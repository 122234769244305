import isTravelBenefitsActive from '../isTravelBenefitsActive';

const DEV = false;
const level = parseInt(localStorage.getItem('nivel'));
const grupo = localStorage.getItem('grupo');
const email = localStorage.getItem('email');
const country = localStorage.getItem('pais');

export const validateMenu = () => {
	if (window.location.hostname === 'localhost') {
		return true;
		// return false;
	} else {
		// Validación de acuerdo al API
		// validación existente previamente
		if (country === 'MX' || country === 'US' || country === 'CA') {
			if (
				// (level < 2 && grupo !== '99 NSD - NO SINDICALIZADOS') ||
				// level > 1 ||
				// (grupo === '99 NSD - NO SINDICALIZADOS' &&
				// 	email === 'nferri@aeromexico.com') ||
				// (grupo === '99 NSD - NO SINDICALIZADOS' &&
				// 	email === 'jsarvis@aeromexico.com')
				level > 0 ||
				level !== null
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
};

export const validateTravelBenefits = () => {
	if (isTravelBenefitsActive()) {
		return true;
	} else {
		return false;
	}
	// if (
	// 	email === "jgarciab@aeromexico.com" ||
	// 	email === "jabadia@aeromexico.com"
	// ) {
	// 	return true;
	// } else {
	// 	return false;
	// }
};
