import * as contentful from 'contentful';


export default class InfoEnvironment {
	api_url = props => {
		if (props.host === 'miaeromexico.com') {
			return 'https://servicios.miaeromexico.com';
		} else {
			return 'https://pruebasservicios.miaeromexico.com';
		}
	};
	clientContenful = props => {
		if (props.host === 'miaeromexico.com' || props.host.includes('localhost' || props.host === 'dyn-miam-prt-ac.amlab7.com')) {
			return contentful.createClient({
				space: process.env.REACT_APP_SPACE_DEV,
				accessToken:process.env.REACT_APP_TOKEN_DEV
			});
		} else {
			return contentful.createClient({
				accessToken:process.env.REACT_APP_TOKEN,
				space: process.env.REACT_APP_SPACE,
				host: process.env.REACT_APP_HOST
			});
		}
	};
}
