// import { ventaNov21 } from '../utils/validatesTickets2020';
import { abril2022Func } from './isVentaEspecialActive';
// import {} from '../utils';
import { travelBenefitsSpecialCases } from './validateTravelBenefitsSpecialCase';
// import { diciembre2021 } from './ventaEspecial2021Diciembre';
// import { febrero2022 } from './ventaEspecialFebrero2022';
import { isLocal, isFromDev, isFromQA } from './../core/Ambientes';

const lodash = require('lodash');
	// # Variables que switchean las ligas que se consumen segun sea elcaso
	// const isLocal = true//process.env.REACT_APP_ISLOCAL; // Tomara entorno local
	// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
	// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
	/* Para productivo se ponen todas en falso */
	
	// #URl de ambiente
	const urlLocal = `${process.env.REACT_APP_LOCAL}`;
	const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
	const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
	const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`
	
	const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
	const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
	const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
	const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`
	
	// # Liga validada
	const urlValid = isLocal === true ? urlLocal 
		: isFromDev === true ? urlDev
		: isFromQA  === true? urlQA
		: urlProd
	
	// Se genera el token segun el ambiente
	const tokenValid = isLocal === true ? tokenLocal
		: isFromDev === true ? tokenDev
		: isFromQA === true ? tokenQA
		: tokenProd
	
		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});


const URL = `${urlValid}`;
let notCalled = false;

export default function isTravelBenefitsActive() {
	if (
		testEmails() ||
		travelSpecificValidation() ||
		specialTravelBenefits(localStorage.getItem('email'))
	) {
		return true;
	} else {
		return false;
	}
	// const email = localStorage.getItem("email");
	// console.log("TRAVEL BENEFITS ACTIVE", email);
	// if (
	// 	email === "jgarciab@aeromexico.com" ||
	// 	email === "jabadia@aeromexico.com"
	// ) {
	// 	return true;
	// } else {
	// 	return false;
	// }
}

export const validateFlexStatus = (data) => {
	switch (data) {
		case "N":
			return "En sitio";
		case "HOME OFFICE FULL-TIME":
			return "Remoto";
		case "HOME OFFICE PART-TIME":
			return "Parcial";
		default:
			return null;
	}
};
const validateFlexStatusBoolean = (data) => {
	switch (data) {
		case "N":
			return true;
		case "HOME OFFICE FULL-TIME":
			return true;
		case "HOME OFFICE PART-TIME":
			return true;
		default:
			return false;
	}
};


export const isFlexibilityActive = async () => {
	const isFlexActive = localStorage.getItem("esquemaColaborador");
	return validateFlexStatusBoolean(isFlexActive);
	// const isFlexActive = localStorage.getItem('flexIsActive');
	// if (!!isFlexActive && isFlexActive !== '') {
	// 	return isFlexActive === 'true' ? true : false;
	// } else {
	// 	const response = await fetch(
	// 		`${URL}/flex-users?email=${localStorage.getItem('email')}`,
	// 		// `${URL}/flex-users?email=dagkoadkg@adkg.com`,
	// 		{
	// 			method: 'GET',
	// 			headers: {
	// 				'Content-Type': 'application/json'
	// 			}
	// 		}
	// 	).then((res) => res.json());
	// 	if (response.length !== 0) {
	// 		localStorage.setItem('flexIsActive', true);
	// 		return true;
	// 	}
	// 	localStorage.setItem('flexIsActive', false);
	// 	return false;
	// }
};

export function isBothActive() {
	if (testEmails()) {
		//console.log("BOTH ACTIVE", true);
		return true;
	} else {
		//console.log("BOTH ACTIVE", false);
		return false;
	}
}

// localStorage.setItem(
// 	"codigo_empresa_AM",
// 	data.codigo_empresa
// );
// localStorage.setItem(
// 	"tipo_empleado_AM",
// 	data.tipo_empleado
// );
// localStorage.setItem("no_empleado_AM", data.noEmpleado);

// function validateCompany(data) {
// 	const code = parseInt(data);
// 	console.log('EMPRESA NUMERO', code);
// 	switch (code) {
// 		case 8:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		case 9:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		case 13:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		case 15:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		case 37:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		default:
// 			console.log('EMPORESA NUMERO', false);
// 			return false;
// 	}
// }

function validateEmployee(data) {
	if (data === "NS") {
		//console.log("NS", true);
		return true;
	} else if (data === "I") {
		const company = localStorage.getItem("grupo");
		const nsd = "NSD";
		if (company.includes(nsd)) {
			return true;
		} else {
			return false;
		}
	} else {
		//console.log("NS", false);
		return false;
	}
}

// function employeeNumber(data) {
// 	const eNumb = parseInt(data);
// 	const resultado = lodash.findIndex(ventaNOPUEDEN, function(o) {
// 		return o.employee === eNumb;
// 	});
// 	if (resultado < 0) {
// 		console.log('employee number', true);
// 		return true;
// 	} else {
// 		console.log('employee number', false);
// 		return false;
// 	}
// }

// function everyValidation() {
// 	if (
// 		validateCompany(localStorage.getItem('codigo_empresa_AM')) &&
// 		validateEmployee(localStorage.getItem('tipo_empleado_AM'))
// 		// employeeNumber(localStorage.getItem('no_empleado_AM'))
// 	) {
// 		console.log('EVERY VALIDATION', true);
// 		return true;
// 	} else {
// 		console.log('EVERY VALIDATION', false);
// 		return false;
// 	}
// }

function testEmails() {
	if (
		localStorage.getItem("email") === "jtapias@aeromexico.com" ||
		localStorage.getItem("email") === "jgarciab@aeromexico.com"
		// localStorage.getItem('email') === 'fsoler@aeromexico.com'
		// localStorage.getItem('email') === 'dcarbajal@aeromexico.com'
		// localStorage.getItem('email') === 'irios@aeromexico.com'
	) {
		//console.log("EMAIL VALIDATION", true);
		return true;
	} else {
		//console.log("EMAIL VALIDATION", false);
		return false;
	}
}

export const hasSpecialSale = async (fromWhere) => {
	console.log("no se de donde vienes",fromWhere);
	console.log(`SPECIAL SALE CALLED AND FALSE FROM: ${fromWhere}`);
	return false;
};

export async function hasSpecialSaleOLD() {
	return false;
	// if (!notCalled) {
	// 	alert('SPECIAL SALE ALERT CALL');
	// 	notCalled = true;
	// 	const hasVenta = await fetch(
	// 		`https://cadmin.miaeromexico.com/ve-usuarios?email_eq=${localStorage.getItem(
	// 			'email'
	// 		)}`
	// 	)
	// 		.then((res) => res.json())
	// 		.catch(console.error);
	// 	const ventaEnabled = await fetch(
	// 		'https://cadmin.miaeromexico.com/menu-items-validator'
	// 	)
	// 		.then((res) => res.json())
	// 		.catch(console.error);
	// 	console.log('hasVENTA: ', hasVenta);
	// 	console.log('ventaEnabled: ', ventaEnabled);
	// 	if (ventaEnabled.venta_especial && hasVenta[0]._id) {
	// 		alert('TRUE');
	// 		console.log('ESPECIAL SALE', true);
	// 		notCalled = false;
	// 		return true;
	// 	} else {
	// 		alert('FALSE');
	// 		console.log('ESPECIAL SALE', false);
	// 		notCalled = false;
	// 		return false;
	// 	}
	// }
}

export function covidTickets() {
	return false;
}

function travelCompany(data) {
	const code = parseInt(data);
	//console.log("TRAVEL EMPRESA NUMERO", code);
	switch (code) {
		case 2:
			//console.log("TRAVEL CAN ENTERPRISE?", true);
			return true;
		case 3:
			//console.log("TRAVEL CAN ENTERPRISE?", true);
			return true;
		case 99:
			//console.log("TRAVEL CAN ENTERPRISE?", true);
			return false;
		default:
			//console.log("TRAVEL CAN ENTERPRISE?", false);
			return false;
	}
}

function travelSpecificValidation() {
	if (
		travelCompany(localStorage.getItem("codigo_empresa_AM")) &&
		validateEmployee(localStorage.getItem("tipo_empleado_AM"))
	) {
		return true;
	} else {
		return false;
	}
}

function specialTravelBenefits(email) {
	const resultado = lodash.findIndex(travelBenefitsSpecialCases, function (o) {
		return o.email === email;
	});
	if (resultado >= 0) {
		//console.log("CASO ESPECIAL TRAVEL BENEFITS");
		return true;
	} else {
		return false;
	}
}
