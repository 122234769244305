import { Component, Fragment } from "react";
import HomeAlert from "../Home/AlertHome/AlertHome";
import Modal from "react-awesome-modal";
import InfoEnvironment from "../../utils/info.environment";

import i18next from "i18next";
import { NavLink, Link } from "react-router-dom";
import LogoDesktop from "../../assets/images/logo/logo-aeromexico.svg";
import LogoPWA from "../../assets/images/logo/app-logo.svg";
import { validateMenu, validateTravelBenefits } from "../../utils/NewBenefitsValidation/benefits.validation";
import { withAccess } from "../../utils/validateTeletrabajo";
import ReactPlaceholder from "react-placeholder";
import core from "../../assets/images/menu-core.svg";
import logout from "../../assets/images/menu-logout.svg";
import notify from "../../assets/images/menu-notify.svg";
import notifyApp from "../../assets/images/menu-notify-app.svg";
import Notifications from "../Header/Notification/Notifications";
import menuSearch from "../../assets/images/menu-search.svg";
import "./css/menuTop.css";
import "react-placeholder/lib/reactPlaceholder.css";
import "../Slider/css/swiper.css";
import "./css/modifier.css";
import { Trans } from "react-i18next";
import MobileMenu from "../MenuMovil/MobileMenu";
import { isFlexibilityActive } from "../../utils/isTravelBenefitsActive";
import isMockUser from "../../utils/isMockUser";
import initLang from "../../utils/initLang";
import setMockCompany from "../../utils/setMockCompany";
import close from "../../assets/logo/close.svg";
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';

var noScroll = require("no-scroll");
const sha1 = require("sha1");
const fakeColaboradores = [
	{
		ANTIGUEDADROL: "21/12/2015",
		AREA: "TESORERIA",
		CORREO: "jzumarraga@aeromexico.com",
		FECHACONTRA: "29/02/2016",
		FECHANACIMIENTO: "18/01/1978",
		GENERO: "Masculino",
		NIVEL: "9",
		NOMBRE: "JUAN PABLO ZUMARRAGA CONTRERAS",
		POSICION: "GERENTE SR DE FINANCIAMIENTO Y COBERTURAS"
	},
	{
		ANTIGUEDADROL: null,
		AREA: "TESORERIA",
		CORREO: "ecchavez@aeromexico.com",
		FECHACONTRA: "15/07/2013",
		FECHANACIMIENTO: "30/09/1975",
		GENERO: "Femenino",
		NIVEL: "5",
		NOMBRE: "ERIKA CAROLINA CHAVEZ ALANIS",
		POSICION: "ASISTENTE ADMINISTRATIVA"
	},
	{
		ANTIGUEDADROL: "09/12/2016",
		AREA: "TESORERIA",
		CORREO: "ecanales@aeromexico.com",
		FECHACONTRA: "19/05/1994",
		FECHANACIMIENTO: "28/04/1972",
		GENERO: "Masculino",
		NIVEL: "9",
		NOMBRE: "JOSE EDUARDO CANALES RUIZ",
		POSICION: "GERENTE SR DE MEDIOS DE PAGO Y PREVENCION"
	},
	{
		ANTIGUEDADROL: "03/12/2021",
		AREA: "TESORERIA",
		CORREO: "vfabian@aeromexico.com",
		FECHACONTRA: "10/02/2016",
		FECHANACIMIENTO: "07/05/1975",
		GENERO: "Masculino",
		NIVEL: "10",
		NOMBRE: "VICTOR MANUEL FABIAN VILLALOBOS",
		POSICION: "DIRECTOR TESORERIA"
	}
];

	// # Variables que switchean las ligas que se consumen segun sea elcaso
	// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
	// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
	// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
	/* Para productivo se ponen todas en falso */
	
	// #URl de ambiente
	const urlLocal = `${process.env.REACT_APP_LOCAL}`;
	const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
	const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
	const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`
	
	const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
	const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
	const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
	const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`
	
	// # Liga validada
	const urlValid = isLocal === true ? urlLocal 
		: isFromDev === true ? urlDev
		: isFromQA  === true? urlQA
		: urlProd
	
	// Se genera el token segun el ambiente
	const tokenValid = isLocal === true ? tokenLocal
		: isFromDev === true ? tokenDev
		: isFromQA === true ? tokenQA
		: tokenProd
	
		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});

export default class Arriba extends Component {
	constructor(props) {
		super(props);
		this.infoEnvironment = new InfoEnvironment();
		this.state = {
			hasScrolled: false,
			charging: false,
			error: false,
			visible: false,
			visible2: false,
			visible3: false,
			generalInfo: {
				nombre: "Undefined"
			},
			data: null,
			lang: "",
			langVisible: false,
			empleadoNum: null,
			canVerifyTeletrabajo: false,
			country: null,
			// hasPerformance: null,
			hasTalentPlanning: false,
			subordinados: [],
			ventaEspecial: false,
			servicios: true,
			publicBenefits: true
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	

	planVueloLink() {
		if (i18next.language === "en") {
			// return '/nosotros/plan-de-vuelo/ingles';
			return `${process.env.REACT_APP_SRC_URL}myhr2021/Plan%20de%20vuelo%202021_ING.pdf`;
			//return "https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/myhr2021/Plan%20de%20vuelo%202021_ING.pdf";
		} else {
			// return '/nosotros/plan-de-vuelo';
			return `${process.env.REACT_APP_SRC_URL}myhr2021/Plan%20de%20vuelo%202021_ESP.pdf`;
			//return "https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/myhr2021/Plan%20de%20vuelo%202021_ESP.pdf";
		}
	}

	defendamosNuestroLink() {
		if (i18next.language === "en") {
			return "/lets-defend-our-sky";
		} else {
			return "/defendamos-nuestro-cielo";
		}
	}

	hasSubordinadosLevel10(subor) {
		const hasUser = subor.find(
			(o) => o.NIVEL == "10" || o.NIVEL == "11" || o.NIVEL == "12" || o.NIVEL == "13" || o.NIVEL == "14"
		);
		if (hasUser !== undefined) {
			return true;
		} else {
			return false;
		}
	}

	talentPlanningValid() {
		const email = localStorage.getItem("email");
		// const subordinados = JSON.parse(localStorage.getItem('subordinados'));
		const nivel = parseInt(localStorage.getItem("nivel"));
		if (
			email === "jgarciab@aeromexico.com" ||
			email === "jtapias@aeromexico.com" ||
			email === "rgomezt@aeromexico.com" ||
			email === "fsoler@aeromexico.com" ||
			email === "igomez@aeromexico.com"
			// email === 'jruvalcaba@aeromexico.com'
		) {
			return true;
		} else if (
			nivel > 9 &&
			this.state.subordinados &&
			this.state.subordinados.length > 0 &&
			this.hasSubordinadosLevel10(this.state.subordinados)
		) {
			return true;
		}
		return false;
	}


	onLangChangedEvent = (e) => {
		var val = e.currentTarget.value;
		i18next.changeLanguage(val);
		this.setState({
			lang: val
		});
		window.location.reload();
	};

	onLangSelectedEvent = (l) => {
		if (this.state.lang === l) {
			this.setState({
				langVisible: false
			});
			return;
		}
		// localStorage.setItem('prevLng', this.state.lang);
		// localStorage.setItem('i18nextLng', l);
		i18next.changeLanguage(l);
		this.setState({
			lang: l,
			langVisible: false
		});

		if (window.location.href.indexOf("plan-de-vuelo") > -1) {
			window.location.href = this.planVueloLink();
		} else {
			if (
				window.location.href.indexOf("defendamos-nuestro-cielo") > -1 ||
				window.location.href.indexOf("lets-defend-our-sky") > -1
			) {
				window.location.href = this.defendamosNuestroLink();
			} else {
				window.location.reload();
			}
		}
	};

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	openModal2() {
		this.setState({
			visible2: true
		});
		noScroll.on();
	}

	closeModal2() {
		this.setState({
			visible2: false
		});
		noScroll.off();
	}

	openModal3() {
		this.getFetcher(`${urlValid}traduccion-${i18next.language}`);
		noScroll.on();
	}

	closeModal3() {
		this.setState({
			visible3: false
		});
		noScroll.off();
	}

	openModalLang() {
		this.setState({
			langVisible: true
		});
		noScroll.on();
	}

	closeModalLang() {
		this.setState({
			langVisible: false
		});
		noScroll.off();
	}
	getMockInfo = async () => {
		if (localStorage.getItem("email") !== null && localStorage.getItem("nombre") !== null) {
			localStorage.setItem("nombre", "Usuario");
			// localStorage.setItem("grupo", "XXXX");
			setMockCompany();

			this.setState({
				error: false,
				charging: false,
				nombre: localStorage.getItem("nombre"),
				compania: localStorage.getItem("compania"),
				grupo: localStorage.getItem("grupo"),
				generalInfo: {
					nombre: localStorage.getItem("nombre"),
					grupo: localStorage.getItem("grupo")
				}
			});
		}
		
	};

	getInfo = async () => {
		if (localStorage.getItem("email") !== null && localStorage.getItem("nombre") !== null) {

			const stringToHex = (str) =>{
				let hex = '';
				for(let i=0; i<str.length;i++){
					hex += str.charCodeAt(i).toString(16).toUpperCase();
				}
				return hex;
			}
			const HOST = "NOMBREHOST";
			const token = sha1(`${HOST}|${localStorage.getItem("email")}`).toUpperCase();
			const email = stringToHex(localStorage.getItem("email")).toUpperCase();
			const URL = this.infoEnvironment.api_url({
				host: window.location.hostname
			});

			let formData = new FormData();
			formData.append("token", token);
			formData.append("email", email);
			formData.append("tipo", "1");
			formData.append("tipo_salida", "JSON");

			fetch(URL, {
				body: formData,
				method: "post"
			})
				.then((data) => data.json())
				.then((data) => {
					localStorage.setItem("nombre", data.nombre);
					localStorage.setItem("pais", data.country);
					localStorage.setItem("grupo", data.grupo);
					localStorage.setItem("compania", data.compania);
					localStorage.setItem("colaboradores", JSON.stringify(data.COLABORADORES));
					localStorage.setItem("codigo_empresa_AM", data.codigo_empresa);
					localStorage.setItem("tipo_empleado_AM", data.tipo_empleado);
					localStorage.setItem("no_empleado_AM", data.noEmpleado);

					this.setState({
						...this.state,
						error: false,
						charging: false,
						nombre: localStorage.getItem("nombre"),
						compania: localStorage.getItem("compania"),
						grupo: localStorage.getItem("grupo"),
						generalInfo: {
							nombre: localStorage.getItem("nombre"),
							grupo: localStorage.getItem("grupo")
						},
						empleadoNum: data.noEmpleado,
						canVerifyTeletrabajo: true,
						country: data.country,
						subordinados: data.COLABORADORES
					});
				})
				.catch((error) => {
					this.setState({
						error: error
					});
				});
		}
		if (
			localStorage.getItem("nombre") === "Anonimo" ||
			localStorage.getItem("nombre") === undefined ||
			localStorage.getItem("nombre") === "undefined"
		) {
			this.setState({
				...this.state,
				publicBenefits: true
			});
		}
		if (window.location.hostname === "localhost") {
			localStorage.setItem("colaboradores", JSON.stringify(fakeColaboradores));
		}
	};

	
	async fetchMenuItems() {
		alert("chinga tu madre");
		try {
			const cabecera = new Headers({
				"Content-type": "application/json",
				Authorization: `Bearer ${process.env.REACT_APP_TOKEN_LOCAL}`
			});

			const allowedURL = `${urlValid}ve-usuarios?filters[email][$contains]=`;
			
			const email = encodeURIComponent(localStorage.getItem("email"));
			
			const hasVentaResponse = await fetch(allowedURL + email, {
				mode: "cors",
				cache: "default",
				headers: cabecera
			});
			if (!hasVentaResponse.ok) {
				throw new Error("Error fetching hasVenta data");
			}
			const hasVentaData = await hasVentaResponse.json();

			const menuItemsResponse = await fetch(`${urlValid}menu-items-validator`, {
				headers: cabecera
			});
			
			if (!menuItemsResponse.ok) {
				throw new Error("Error fetching menu items data");
			}
			const menuItemsData = await menuItemsResponse.json();

			this.setState({
				...this.state,
				ventaEspecial: hasVentaData.data.length > 0 ? menuItemsData.venta_especial : false,
				servicios: menuItemsData.servicios
			});
		} catch (error) {
			console.error("Error:", error);
		}
	}

	async componentDidMount() {
		await this.fetchMenuItems();
		// VALIDATES IF PUBLIC DATA HAS BEEN LOADED
		const fiveMinutes = 60 * 5 * 1000;
		if (!this.props.publicUserData || new Date() - new Date(this.props.publicLoadedAt) > fiveMinutes) {
			// LOADS PUBLIC DATA FROM REDUX
			this.props.getPublicUser();
		}
		initLang(this);
		this.handleScroll();
		window.addEventListener("scroll", this.handleScroll);
		document.addEventListener("keydown", this.escFunction, false);
		isMockUser() ? this.getMockInfo() : this.getInfo();
		fetch(
			encodeURI(`${process.env.REACT_APP_CADMIN}/tp-hrbp-data?hrbp_email_eq=${localStorage.getItem("email")}`, {
				cors: "no-cors"
			})
		)
			.then((res) => res.json())
			.then((resData) => {
				if (resData.length > 0) {
					alert('talent planning');
					this.setState({
						...this.state,
						hasTalentPlanning: true
					});
				} else {
					alert('not talent');
					this.setState({
						...this.state,
						hasTalentPlanning: false
					});
				}
			})
			.catch(console.error);
	}

	isMockUserVenta() {
		return (
			localStorage.getItem("email") === "amaccessguest@aeromexico.com" ||
			localStorage.getItem("email") === "jabadia@aeromexico.com" ||
			localStorage.getItem("email") === "irios@aeromexico.com"
		);
	}

	tabSelector = (evt) => {
		this.setState({
			tabActive: evt.currentTarget.attributes.name.textContent
		});
	};

	handleScroll = (event) => {
		const scrollTop = window.pageYOffset;
		if (scrollTop > 50) {
			this.setState({ hasScrolled: true });
		} else {
			this.setState({ hasScrolled: false });
		}
	};

	logout = () => {
		var lang = localStorage.getItem("i18nextLng");
		localStorage.clear();
		sessionStorage.clear();
		localStorage.setItem("i18nextLng", lang);
	};

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}

	getFetcher(url) {
		 
		fetch(url, { headers: cabecera })
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				// console.log("JSON ARRIBA TRADUCCION ES: ", json);
				this.setState({
					...this.state,
					visible3: true,
					data: json.data.attributes.data.content
				});
			});
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false,
				visible2: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = (e) => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false,
				visible2: false
			});
			noScroll.off();
		}
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	render() {
		const { charging } = this.state;

		const twoLinesStyle = {
			lineHeight: "1.5",
			paddingTop: "11px"
		};

		return (
			<div className="contMenuFixed">
				{/* {window.location.pathname === "/inicio" ? <HomeAlert /> : ""} #Se comenta ventana de mantenimiento*/}

				<Modal
					visible={this.state.visible}
					width="100%"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal()}>
					<div ref={this.setWrapperRef} className="modal-notification">
						<div className="scrollModalAlert">
							<div className="modalPadding">
								<div
									className="closeModal"
									onClick={() => {
										this.closeModal();
									}}>
									<img src={close} alt="Close modal" />
								</div>
								<h1>Contactos GAM</h1>
								<p>
									Encuentra los números de contacto de las distintas áreas de asistencia de Grupo
									Aeroméxico.
								</p>
								<div className="tableGamContact">
									<ol>
										<li>
											<div className="leftTable">
												<p>Servicios Internos</p>
											</div>
											<div className="leftTablePhone">
												<a href="+525591324535">(55) 9132 4535</a>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>Seguridad</p>
											</div>
											<div className="leftTablePhone">
												<a href="+525591324697">(55) 9132 4697</a> -{" "}
												<a href="+525591324852">(55) 9132 4852</a>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>Lobby Torre Mapfre</p>
											</div>
											<div className="leftTablePhone">
												<a href="tel:+525591324647">(55) 9132 4647</a>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>Mi línea de atención</p>
											</div>
											<div className="leftTablePhone">
												<a href="tel:+525591324488">(55) 9132 4488</a>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>Call center</p>
											</div>
											<div className="leftTablePhone">
												<a href="tel:+525551334000">(55) 5133 4000</a> -{" "}
												<a href="tel:+525591324488">(55) 9132 4488</a>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>Caja de nómina y pagaduría</p>
											</div>
											<div className="leftTablePhone">
												<a href="+525556565659">(55) 5656 5659</a> -{" "}
												<a href="tel:+525556575660">(55) 5657 5660</a>
											</div>
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<Modal
					visible={this.state.visible2}
					width="100%"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal2()}>
					<div ref={this.setWrapperRef} className="modal-notification">
						<div className="scrollModalAlert">
							<div className="modalPadding">
								<div
									className="closeModal"
									href="javascript:void(0);"
									onClick={() => this.closeModal2()}>
									<img src={close} alt="close" />
								</div>
								<h1>{i18next.t("MainMenu.nosotros.otros.localidadescdmx")}</h1>
								<p>{i18next.t("MainMenu.nosotros.otros.direcciones")}</p>
								<div className="tableGamContact">
									<ol className="lugaresDirecciones">
										<li>
											<div className="leftTable">
												<p>Reforma</p>
											</div>
											<div className="leftTablePhone">
												<p className="small">Torre Mapfre, Avenida Paseo de la Reforma</p>
												<p className="small smallWhite">
													No. 243 Col. Renacimiento, Del. Cuauhtémoc, C.P. 06500, Ciudad de
													México
												</p>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>CECAM | Terminal de servicios</p>
											</div>
											<div className="leftTablePhone">
												<p className="small">Av. Tahel</p>
												<p className="small smallWhite">
													S/N Col. Pensador Mexicano Del. Venustiano Carranza C.P. 15510,
													Ciudad de México
												</p>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>Hangar Oriente</p>
											</div>
											<div className="leftTablePhone">
												<p className="small">Av. Rio Churubusco</p>
												<p className="small smallWhite">
													No. 16 Aeropuerto Internacional Benito Juárez, C.P. 15640, Ciudad de
													México
												</p>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>Hangar Connect</p>
											</div>
											<div className="leftTablePhone">
												<p className="small">
													Eje1 Norte. Av. Hangares de Aviación Fuerza Aérea Mexicana
												</p>
												<p className="small smallWhite">No. 380 C.P. 15700 Ciudad de México</p>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>Aeroméxico Cargo</p>
											</div>
											<div className="leftTablePhone">
												<p className="small">Comodoro P.A. Carlos Castillo Bretón</p>
												<p className="small smallWhite">
													S/N Terminal de carga Zona D, Hangares Terminal 2 AICM, C.P. 15520,
													Ciudad de México
												</p>
											</div>
										</li>

										<li>
											<div className="leftTable">
												<p>AICM T2</p>
											</div>
											<div className="leftTablePhone">
												<p className="small">Aeropuerto Internacional de la Ciudad de México</p>
												<p className="small smallWhite">
													Av. Capt. Carlos León S/N Terminal 2 - Bahía L2, Zona Federal C.P.
													15620 México, D.F.
												</p>
											</div>
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<Modal
					visible={this.state.visible3}
					width="100%"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal3()}>
					{this.state.visible3 && (
						<div ref={this.setWrapperRef} className="modal-notification">
							<div className="scrollModalAlert">
								<div className="modalPadding">
									<div
										className="closeModal"
										href="javascript:void(0);"
										onClick={() => this.closeModal3()}>
										<img src={close} alt="close_img" />
									</div>
									<h1>{this.state.data.MainMenu.nosotros.otros.conservacion_contenido.titulo}</h1>
									<p>{this.state.data.MainMenu.nosotros.otros.conservacion_contenido.subtitulo}</p>
									<div className="tableGamContact">
										<ol className="lugaresDirecciones">
											{this.state.data.MainMenu.nosotros.otros.conservacion_contenido.localidades.map(
												(item, index) => {
													return (
														<li key={index}>
															<div className="leftTable">
																<p>{item.lugar}</p>
															</div>
															<div className="leftTablePhone">
																<p className="small">{item.nombre}</p>
																<p
																	className="small smallWhite"
																	dangerouslySetInnerHTML={{
																		__html: item.contenido
																	}}></p>
															</div>
														</li>
													);
												}
											)}
										</ol>
									</div>
								</div>
							</div>
						</div>
					)}
				</Modal>

				{/* //////////////////////////////////////////////////// */}
				<div className="TopMiAmWrap">
					{window.location.pathname === "/inicio" ? (
						<div id="MenuContainerInicio">
							<NavLink to="/inicio" activeClassName="menu-active" className="textMenu"></NavLink>
						</div>
					) : (
						""
					)}

					<div className="MenuContainer">
						<div
							className={`
								${this.state.hasScrolled ? "MenuWrap HeaderScrolled " : "MenuWrap "}
								${i18next.t("MainMenu.holiday")}
							`}>
							<div className="christmas-items"></div>
							<div className="LogoMiAM">
								{/* {this.props.publicUserData ? ( */}
								<Link to="/inicio">
									<img className="LogoDesktop" src={LogoDesktop} width="170px" />
									<img className="LogoPWA" src={LogoPWA} width="100" />
								</Link>
							</div>
							<div className="ListMenu">
								<a className="menuhoverline oursHoverMenu oursHoverMenu02">
									<NavLink to="/inicio" activeClassName="menu-active">
										<span>{i18next.t("MainMenu.inicio")}</span>
									</NavLink>
								</a>
								<a className="menuhoverline oursHoverMenu oursHoverMenu02">
									<NavLink to="/nosotros" activeClassName="menu-active">
										<span>{i18next.t("MainMenu.nosotros.self")}</span>{" "}
									</NavLink>
									<div class="hoverMenu hoverMenuMore oursmenuhover">
										<ul>
											<div className="separadorMenu">
												<li class="toolsOffice">
													<a>
														{i18next.t("MainMenu.nosotros.grupoAero.self")}
														<div className="hoverherramientasMenu">
															<ul>
																<li>
																	<Link to="/nosotros" activeClassName="activeDrop">
																		{i18next.t(
																			"MainMenu.nosotros.grupoAero.vision"
																		)}
																	</Link>
																</li>
																<li>
																	<Link
																		to="/nosotros#nuestra-cultura"
																		activeClassName="">
																		{i18next.t(
																			"MainMenu.nosotros.grupoAero.cultura"
																		)}
																	</Link>
																</li>

																<li>
																	<Link
																		to="/nosotros#subsidiarias-aeromexico"
																		activeClassName="">
																		{i18next.t("MainMenu.nosotros.grupoAero.subsi")}
																	</Link>
																</li>
																<li>
																	<Link
																		to="/nosotros/nuestra-organizacion"
																		activeClassName="">
																		{i18next.t(
																			"MainMenu.nosotros.grupoAero.lideres"
																		)}
																	</Link>
																</li>

																<li>
																	<Link
																		to="/nosotros/nuestra-flota"
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.nosotros.grupoAero.flota")}
																	</Link>
																</li>

																<li>
																	<NavLink
																		to="/nosotros#nuestros-aeropuertos"
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.nosotros.grupoAero.aero")}
																	</NavLink>
																</li>
																<li>
																	<NavLink
																		to="/nosotros/responsabilidad-social"
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.nosotros.sosten")}
																	</NavLink>
																</li>
																<li
																	className="toolsOfficenosotros"
																	onMouseEnter={function () {
																		document.getElementById(
																			"hideThisMenuABC"
																		).style.display = "block";
																	}}
																	onMouseLeave={function () {
																		document.getElementById(
																			"hideThisMenuABC"
																		).style.display = "none";
																	}}>
																	<a>
																		{i18next.t("MainMenu.nosotros.reportes.self")}
																		<div
																			id="hideThisMenuABC"
																			className="hoverherramientasMenu"
																			style={{
																				display: "none"
																			}}>
																			<ul>
																				<li>
																					<Link to="/nosotros/reporte-operacional">
																						{i18next.t(
																							"MainMenu.nosotros.reportes.opera"
																						)}
																					</Link>
																				</li>
																				<li>
																					<Link to="/nosotros/reporte-trimestral">
																						{i18next.t(
																							"MainMenu.nosotros.reportes.trimes"
																						)}
																					</Link>
																				</li>
																				<li>
																					<Link to="/nosotros/reporte-anual">
																						{i18next.t(
																							"MainMenu.nosotros.reportes.anual"
																						)}
																					</Link>
																				</li>
																				<li>
																					<Link to="/nosotros/boletin-industria">
																						{i18next.t(
																							"MainMenu.nosotros.reportes.industria"
																						)}
																					</Link>
																				</li>
																				<li>
																					<Link to="/nosotros/responsabilidad-social#reportes-sostenibilidad">
																						{i18next.t(
																							"MainMenu.nosotros.reportes.sosten"
																						)}
																					</Link>
																				</li>
																			</ul>
																		</div>
																	</a>
																</li>
															</ul>
														</div>
													</a>
												</li>
												<li>
													<a
														href={
															i18next.language === "en"											
																? `${process.env.REACT_APP_SRC_URL}/plan-vuelo/2024/PLAN_DE_VUELO_2024_RGB_ING.pdf`
																: `${process.env.REACT_APP_SRC_URL}/plan-vuelo/2024/PLAN_DE_VUELO_2024_RGB_ESP.pdf`
																//</li>? `${process.env.REACT_APP_SRC_URL}/plan-vuelo/2023/PLAN%20DE%20VUELO%202023_ING.pdf`
															//: `${process.env.REACT_APP_SRC_URL}/plan-vuelo/2023/PLAN%20DE%20VUELO%202023_ESP.pdf`
														}
														target="_blank"
														rel="noopener noreferrer">
														{i18next.t("MainMenu.nosotros.planVuelo")}
													</a>
												</li>
											</div>
											<div className="separadorMenu">
												<li className="toolsOfficeOtros">
													<a>
														{i18next.language === "es" ? "Alianzas" : "Alliance"}
														<div className="hoverherramientasMenu hoverherramientasMenuOtros">
															<ul>
																<li>
																	<NavLink
																		to="/nosotros/jca-aeromexico-delta"
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.nosotros.jca")}
																	</NavLink>
																</li>
																<li>
																	<a
																		href="https://www.aeromexico.com/es-mx/vuela-con-nosotros/otras-alianzas"
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t("MainMenu.nosotros.sky")}
																	</a>
																</li>
															</ul>
														</div>
													</a>
												</li>
												{/* <li className="singleLine-menu-item"> */}
												<li className="singleLine-menu-item">
													<NavLink to="/juntoscontinuaremosvolando" activeClassName="activeDrop">
														{i18next.language === "es"
															? "Juntos continuaremos volando"
															: "Together we will continue to fly"}
													</NavLink>
												</li>
												<li>
													<NavLink
														to="/nosotros/seguimos-y-seguiremos-volando"
														activeClassName="activeDrop">
														{i18next.language === "es" ? "Capítulo 11" : "Chapter 11"}
													</NavLink>
												</li>

												{/* <li className="toolsOfficeOtros"> */}
												<li className="toolsOffice">
													<a>
														{i18next.t("MainMenu.nosotros.otros.self")}
														<div className="hoverherramientasMenu hoverherramientasMenuOtros">
															<ul>
																<li>
																	<NavLink
																		to={this.defendamosNuestroLink()}
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.nosotros.cielo")}
																	</NavLink>
																</li>
																<li>
																	<NavLink 
																		to="/nosotros/gam-talks"
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.nosotros.gam")}
																	</NavLink>
																</li>
																<li className="tall">
																	<NavLink
																		to="/nosotros/logos-templates"
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.nosotros.otros.identidad")}
																		<p class="meaning">
																			{i18next.t(
																				"MainMenu.nosotros.otros.identidadSub"
																			)}
																		</p>
																	</NavLink>
																</li>

																<li>
																	<a
																		onClick={() => this.openModal2()}
																		activeClassName="activeDrop">
																		{i18next.t(
																			"MainMenu.nosotros.otros.localidades"
																		)}
																	</a>
																</li>
																{/* MENU DIRECTORIO DE CONSERVACIÓN Y MANTENIMIENTO */}
																<li className="tall">
																	<a
																		onClick={() => this.openModal3()}
																		activeClassName="activeDrop"
																		style={twoLinesStyle}>
																		{i18next.t(
																			"MainMenu.nosotros.otros.conservacion"
																		)}
																	</a>
																</li>

																<li
																// className="tall"
																>
																	<a
																		href={i18next.t(
																			"MainMenu.nosotros.otros.estacionesLiga"
																		)}
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t(
																			"MainMenu.nosotros.otros.estaciones"
																		)}
																	</a>
																</li>

																<li>
																	<NavLink
																		to="/nosotros/alfabeto"
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.nosotros.otros.alfabeto")}
																	</NavLink>
																</li>
															</ul>
														</div>
													</a>
												</li>
											</div>
										</ul>
									</div>
								</a>
								<a className="menuhoverline seguridadhover">
									<NavLink to="/seguridad" activeClassName="menu-active">
										<span>{i18next.t("MainMenu.seguridad.self")}</span>
									</NavLink>
									<div class="hoverMenu hoverMenuMore seguridadhovermore">
										<ul>
											<li>
												<Link exact={true} to="/seguridad" activeClassName="activeDrop">
													{i18next.t("MainMenu.seguridad.primero")}
												</Link>
											</li>
											<li>
												<Link exact={true} to="/seguridad/nom-035" activeClassName="activeDrop">
													NOM-035
												</Link>
											</li>
											<li>
												<Link to="/seguridad#cultura-justa">
													{i18next.t("MainMenu.seguridad.justa")}
												</Link>
											</li>
											<li>
												<Link to="/seguridad#principios-de-seguridad">
													{i18next.t("MainMenu.seguridad.principios")}
												</Link>
											</li>
											<li>
												<Link to="/seguridad#areas-de-seguridad">
													{i18next.t("MainMenu.seguridad.areas")}
												</Link>
											</li>

											<li className="reportaGamli">
												<Link
													// to="reportaGam"
													to="/seguridad#reporta-denuncia">
													{i18next.t("MainMenu.seguridad.reporta")}
												</Link>
											</li>

											<li>
												<Link to="/noticias/seguridad">
													{i18next.t("MainMenu.seguridad.noticias")}
												</Link>
											</li>

											<li className="singleLine-menu-item">
												<Link to="/articles/2yo9ERxlWAlWHFxv3DhOXI">
													{i18next.t("MainMenu.seguridad.politics")}
												</Link>
											</li>
										</ul>
									</div>
								</a>
								<a class="experienceHover menuhoverline" activeClassName="menu-active" to="/profile">
									{(localStorage.getItem("nivel") === "1" &&
										localStorage.getItem("grupo") != "99 NSD - NO SINDICALIZADOS") ||
									localStorage.getItem("nivel") === "2" ||
									localStorage.getItem("nivel") === "3" ||
									localStorage.getItem("nivel") === "4" ||
									localStorage.getItem("nivel") === "5" ||
									localStorage.getItem("nivel") === "6" ||
									localStorage.getItem("nivel") === "7" ||
									localStorage.getItem("nivel") === "8" ||
									localStorage.getItem("nivel") === "9" ||
									localStorage.getItem("nivel") === "10" ||
									localStorage.getItem("nivel") === "11" ||
									localStorage.getItem("nivel") === "12" ||
									(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
										localStorage.getItem("email") === "nferri@aeromexico.com") ||
									(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
										localStorage.getItem("email") === "jsarvis@aeromexico.com") ? (
										<Link activeClassName="menu-active" to="/recompensa-total">
											<span>{i18next.t("MainMenu.miExpAM.self")}</span>
										</Link>
									) : (
										""
									)}

									{localStorage.getItem("nivel") !== "1" &&
									localStorage.getItem("nivel") !== "2" &&
									localStorage.getItem("nivel") !== "3" &&
									localStorage.getItem("nivel") !== "4" &&
									localStorage.getItem("nivel") !== "5" &&
									localStorage.getItem("nivel") !== "6" &&
									localStorage.getItem("nivel") !== "7" &&
									localStorage.getItem("nivel") !== "8" &&
									localStorage.getItem("nivel") !== "9" &&
									localStorage.getItem("nivel") !== "10" &&
									localStorage.getItem("nivel") !== "11" &&
									localStorage.getItem("nivel") !== "12" ? (
										<a activeClassName="menu-active" to="/recompensa-total">
											<span>{i18next.t("MainMenu.miExpAM.self")}</span>
										</a>
									) : (
										""
									)}

									<div class="hoverMenu hoverMenuExperience">
										<ul>
											<div className="leftTest">
												{/* MENU COMPENSACIONES */}
												{/* AQUI NUEVA VALIDACION */}
												{validateMenu() ? (
													<Fragment>
														<li className="menuRewards">
															{/* /****REVISAR TODO ESTO */
															/* /****REVISAR TODO ESTO */
															/* /****REVISAR TODO ESTO */
															/* /****REVISAR TODO ESTO */
															/* /****REVISAR TODO ESTO */}
															<a activeClassName="activeDrop">
																{i18next.t("MainMenu.miExpAM.recompensa.self")}
																<p className="meaning">
																	{i18next.t("MainMenu.miExpAM.recompensa.sub")}
																</p>
																{/* SUBMENU */}
																<div className="hoverCompensacionesMenu">
																	<ul>
																		<li className="whitespace-nowrap">
																			<NavLink to="/recompensa-total/perfil/mi-compensaciones">
																				{i18next.t(
																					"MainMenu.miExpAM.recompensa.compensa"
																				)}
																			</NavLink>
																		</li>
																		{/* TRAVEL BENEFITS */}
																		{/*Travel benefits condicionado */}
																		{validateTravelBenefits() ? (
																			<li>
																				<NavLink to="/recompensa-total/perfil/my-travel-benefits">
																					{i18next.t(
																						"MainMenu.miExpAM.recompensa.travel"
																					)}
																				</NavLink>
																			</li>
																		) : (
																			""
																		)}
																		{this.state.ventaEspecial ? (
																			<li>
																				<NavLink to="/recompensa-total/perfil/venta-especial">
																					{i18next.t(
																						"MainMenu.miExpAM.recompensa.venta"
																					)}
																				</NavLink>
																			</li>
																		) : null}

																		<li>
																			<NavLink to="/recompensa-total/perfil/mis-beneficios">
																				{i18next.t(
																					"MainMenu.miExpAM.recompensa.benef"
																				)}
																			</NavLink>
																		</li>
																		{isFlexibilityActive() ? (
																			<li className="whitespace-nowrap">
																				<NavLink to="/recompensa-total/perfil/my-flexibility">
																					My Flexibility
																				</NavLink>
																			</li>
																		) : null}
																		{this.state.canVerifyTeletrabajo &&
																		withAccess(this.state.empleadoNum) ? (
																			<li>
																				<NavLink
																					activeClassName="activeDrop"
																					to="/ayuda-teletrabajo">
																					{i18next.t(
																						"MainMenu.miExpAM.miDesem.teletrabajo"
																					)}
																				</NavLink>
																			</li>
																		) : null}
																	</ul>
																</div>
															</a>
														</li>
													</Fragment>
												) : (
													""
												)}

												{(localStorage.getItem("nivel") === "1" &&
													localStorage.getItem("grupo") != "99 NSD - NO SINDICALIZADOS") ||
												localStorage.getItem("nivel") === "2" ||
												localStorage.getItem("nivel") === "3" ||
												localStorage.getItem("nivel") === "4" ||
												localStorage.getItem("nivel") === "5" ||
												localStorage.getItem("nivel") === "6" ||
												localStorage.getItem("nivel") === "7" ||
												localStorage.getItem("nivel") === "8" ||
												localStorage.getItem("nivel") === "9" ||
												localStorage.getItem("nivel") === "10" ||
												localStorage.getItem("nivel") === "11" ||
												localStorage.getItem("nivel") === "12" ||
												(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
													localStorage.getItem("email") === "nferri@aeromexico.com") ||
												(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
													localStorage.getItem("email") === "jsarvis@aeromexico.com") ||
												localStorage.getItem("email") === "jgarciab@aeromexico.com" ? (
													<li className={"toolsOffice develop " + this.state.lang}>
														<a>
															{i18next.t("MainMenu.miExpAM.miDesem.self")}
															<p className="meaning">
																{i18next.t("MainMenu.miExpAM.miDesem.self2")}
															</p>
															<div className="hoverherramientasMenu">
																<ul>
																	<li>
																		<NavLink
																			activeClassName="activeDrop"
																			to="/recompensa-total/mi-desempeno-desarrollo">
																			{i18next.t(
																				"MainMenu.miExpAM.miDesem.desarrolla"
																			)}
																		</NavLink>
																	</li>
																	{parseInt(localStorage.getItem("nivel")) > 0 ? (
																		<li>
																			<NavLink
																				activeClassName="activeDrop"
																				to="/recompensa-total/my-learning">
																				My Learning
																			</NavLink>
																		</li>
																	) : (
																		""
																	)}

																	{this.state.hasTalentPlanning ? (
																		<li>
																			<NavLink
																				activeClassName="activeDrop"
																				to="/recompensa-total/talent-planning">
																				Talent Planning
																			</NavLink>
																		</li>
																	) : this.talentPlanningValid() ? (
																		<li>
																			<NavLink
																				activeClassName="activeDrop"
																				to="/recompensa-total/talent-planning">
																				Talent Planning
																			</NavLink>
																		</li>
																	) : null}

																	<li className="singleLine-menu-item">
																		<a
																			activeClassName="activeDrop"
																			href="https://lms.aeromexico.com/"
																			target="_blank"
																			rel="noopener noreferrer">
																			{i18next.t("MainMenu.miExpAM.miDesem.lms")}
																		</a>
																	</li>
																</ul>
															</div>
														</a>
													</li>
												) : (
													""
												)}
												{/* {this.state.publicBenefits ? (
													<li className="whitespace-nowrap">
														<Link activeClassName="activeDrop" to="/mis-beneficios">
															Mis beneficios
														</Link>
													</li>
												) : null} */}


												{/* Inicia Menú oculto Oct 2024 */}
												{/* Se oculta el 08 Oct 2024 */}
												{/* Solicitado por Jessica Tapia */}
												{/* <div className="hoverherramientasMenu">
													<ul>
														<li>
															<NavLink
																activeClassName="activeDrop"
																to="/recompensa-total/mi-desempeno-desarrollo">
																{i18next.t("MainMenu.miExpAM.miDesem.desarrolla")}
															</NavLink>
														</li>
														{parseInt(localStorage.getItem("nivel")) > 0 ? (
															<li>
																<NavLink
																	activeClassName="activeDrop"
																	to="/recompensa-total/my-learning">
																	My Learning
																</NavLink>
															</li>
														) : (
															""
														)} */}

														{/* <li>
																		<NavLink
																			activeClassName="activeDrop"
																			to="/recompensa-total/oportunidades-carrera"
																		>
																			{i18next.t(
																				'MainMenu.miExpAM.miDesem.vacantes'
																			)}
																		</NavLink>
																	</li> */}
														{/* {this.state.hasTalentPlanning ? (
															<li>
																<NavLink
																	activeClassName="activeDrop"
																	to="/recompensa-total/talent-planning">
																	Talent Planning
																</NavLink>
															</li>
														) : this.talentPlanningValid() ? (
															<li>
																<NavLink
																	activeClassName="activeDrop"
																	to="/recompensa-total/talent-planning">
																	Talent Planning
																</NavLink>
															</li>
														) : null} */}

														{/* {(this.state
																		.hasTalentPlanning ||
																		this.talentPlanningValid()) && (
																		<>
																			{alert(
																				'SHOW TALENT'
																			)}
																			<li>
																				<NavLink
																					activeClassName="activeDrop"
																					to="/recompensa-total/talent-planning"
																				>
																					Talent
																					Planning
																				</NavLink>
																			</li>
																		</>
																	)} */}
														{/* {this.state
																		.hasPerformance ||
																	localStorage.getItem(
																		'email'
																	) ===
																		'jgarciab@aeromexico.com' ||
																	localStorage.getItem(
																		'email'
																	) ===
																		'jtapias@aeromexico.com' ? (
																		<li>
																			<NavLink
																				activeClassName="activeDrop text-amRed"
																				to="/recompensa-total/performance-review-2021"
																			>
																				Performance
																				Review
																				2021
																			</NavLink>
																		</li>
																	) : null} */}

														{/* {localStorage.getItem(
																		'nivel'
																	) === '6' ||
																	localStorage.getItem(
																		'nivel'
																	) === '7' ||
																	localStorage.getItem(
																		'nivel'
																	) === '8' ||
																	localStorage.getItem(
																		'nivel'
																	) === '9' ||
																	localStorage.getItem(
																		'nivel'
																	) ===
																		'10' ||
																	localStorage.getItem(
																		'nivel'
																	) ===
																		'11' ||
																	localStorage.getItem(
																		'nivel'
																	) ===
																		'12' ? (
																		<li>
																			<NavLink
																				activeClassName="activeDrop"
																				to="/recompensa-total/my-hr"
																			>
																				{i18next.t(
																					'MainMenu.miExpAM.miDesem.hr'
																				)}
																			</NavLink>
																		</li>
																	) : (
																		''
																	)} */}

														{/* <li className="singleLine-menu-item">
															<a
																activeClassName="activeDrop"
																href="https://lms.aeromexico.com/"
																target="_blank"
																rel="noopener noreferrer">
																{i18next.t("MainMenu.miExpAM.miDesem.lms")}
															</a>
														</li>
													</ul>
												</div> */}
												{/* Solicitado por Jessica Tapia */}
												{/* Se oculta el 08 Oct 2024 */}
												{/* Termina Menú oculto Oct 2024 */}

												<li className="whitespace-nowrap">
													<NavLink activeClassName="activeDrop" to="/compliance">
														Compliance
													</NavLink>
												</li>
												<li class="toolsOffice develop whitespace-nowrap">
													<NavLink activeClassName="activeDrop" to="/juntos-desde-casa">
														{i18next.language === "es"
															? "Juntos desde casa"
															: "Together from home"}
													</NavLink>
												</li>

												{/* 	{localStorage.getItem("nivel") !== "1" &&
												localStorage.getItem("nivel") !== "2" &&
												localStorage.getItem("nivel") !== "3" &&
												localStorage.getItem("nivel") !== "4" &&
												localStorage.getItem("nivel") !== "5" &&
												localStorage.getItem("nivel") !== "6" &&
												localStorage.getItem("nivel") !== "7" &&
												localStorage.getItem("nivel") !== "8" &&
												localStorage.getItem("nivel") !== "9" &&
												localStorage.getItem("nivel") !== "10" &&
												localStorage.getItem("nivel") !== "11" &&
												localStorage.getItem("nivel") !== "12" ? (
													<li className="whitespace-nowrap">
														<Link
															activeClassName="activeDrop"
															to="/recompensa-total/oportunidades-carrera">
															{i18next.t("MainMenu.miExpAM.miDesem.vacantes")}
														</Link>
													</li>
												) : (
													""
												)} */}

												<li className="toolsOffice whitespace-nowrap">
													<a>
														{i18next.t("MainMenu.miExpAM.herramientas.self")}
														<div className="hoverherramientasMenu">
															<ul>
																<li>
																	<a
																		href="https://outlook.office365.com/owa/?realm=aeromexico.com&exsvurl=1&ll-cc=2058&modurl=0"
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t(
																			"MainMenu.miExpAM.herramientas.outlook"
																		)}
																	</a>
																</li>
																<li>
																	<a
																		href="https://www.office.com"
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t(
																			"MainMenu.miExpAM.herramientas.office"
																		)}
																	</a>
																</li>
																<li>
																	<a
																		href="https://grupoaeromexico-my.sharepoint.com/_layouts/15/MySite.aspx?MySiteRedirect=AllDocuments"
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t(
																			"MainMenu.miExpAM.herramientas.onedrive"
																		)}
																	</a>
																</li>
																<li>
																	<a
																		href="https://aka.ms/mstfw"
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t(
																			"MainMenu.miExpAM.herramientas.teams"
																		)}
																	</a>
																</li>
																<li>
																	<a
																		href="https://webapp.kaiza.la/"
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t(
																			"MainMenu.miExpAM.herramientas.kaizala"
																		)}
																	</a>
																</li>
																<li>
																	<a
																		href="https://grupoaeromexico.sharepoint.com/_layouts/15/sharepoint.aspx"
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t(
																			"MainMenu.miExpAM.herramientas.sharepoint"
																		)}
																	</a>
																</li>
															</ul>
														</div>
													</a>
												</li>
												{/* 
												{localStorage.getItem("nivel") !== "1" &&
												localStorage.getItem("nivel") !== "2" &&
												localStorage.getItem("nivel") !== "3" &&
												localStorage.getItem("nivel") !== "4" &&
												localStorage.getItem("nivel") !== "5" &&
												localStorage.getItem("nivel") !== "6" &&
												localStorage.getItem("nivel") !== "7" &&
												localStorage.getItem("nivel") !== "8" &&
												localStorage.getItem("nivel") !== "9" &&
												localStorage.getItem("nivel") !== "10" &&
												localStorage.getItem("nivel") !== "11" &&
												localStorage.getItem("nivel") !== "12" ? (
													<li className="whitespace-nowrap">
														<Link to="/recompensa-total/mis-beneficios/descuentos">
															{i18next.t("MainMenu.miExpAM.descuentos")}
														</Link>
													</li>
												) : (
													""
												)} */}
											</div>

											<div className="leftTest" style={{ borderRight: "none" }}>
												<li className="whitespace-nowrap">
													<a
														href="https://autoservicio.aeromexico.com/"
														target="_blank"
														rel="noopener noreferrer">
														{i18next.t("MainMenu.miExpAM.core")}
													</a>
												</li>

												{/* <li>
                          <a href="http://lms.aeromexico.com/" target="_blank">
                            Capacitación en Línea (LMS)
                          </a>
                        </li> */}

												<li className="viajescom whitespace-nowrap">
													<a>
														{i18next.t("MainMenu.miExpAM.viajes.self")}
														<div className="viajesmenuhover">
															<ul>
																<li>
																	<NavLink
																		to="/recompensa-total/viajes-aeromexico"
																		activeClassName="activeDrop">
																		{i18next.t("MainMenu.miExpAM.viajes.conAero")}
																	</NavLink>
																</li>

																<li
																	style={{
																		height: "63px"
																	}}>
																	<a
																		href="https://grupoaeromexico.sharepoint.com/sites/Pass_Bureau_Aeromexico/Paginas/Inicio.aspx"
																		target="_blank"
																		rel="noopener noreferrer">
																		{i18next.t("MainMenu.miExpAM.viajes.conOtras")}
																		<p class="meaning">
																			{i18next.t(
																				"MainMenu.miExpAM.viajes.conOtrasSub"
																			)}
																		</p>
																	</a>
																</li>
															</ul>
														</div>
													</a>
												</li>

												<li>
													<NavLink activeClassName="activeDrop" to="/noticias/nuestragente">
														{i18next.t("MainMenu.miExpAM.noticias")}
													</NavLink>
												</li>
												<li>
													<NavLink activeClassName="activeDrop" to="/mi-linea-atencion">
														{/* {i18next.t("MainMenu.mas.linea")} */}
														{i18next.language === "es"
															? "Mi línea de atención"
															: "My hotline"}
													</NavLink>
												</li>
												{this.state.servicios ? (
													<li>
														<NavLink activeClassName="activeDrop" to="/servicios-personal">
															{/* {i18next.t("MainMenu.mas.procesos")} */}
															{i18next.language === "es"
																? "Servicios al personal"
																: "Staff services"}
														</NavLink>
													</li>
												) : null}

												{this.state.servicios ? (
													<li>
														<NavLink activeClassName="activeDrop" to="/acciones-personales">
															{/* {i18next.t("MainMenu.mas.procesos")} */}
															{i18next.language === "es"
																? "Entrega de acciones"
																: "Delivery of shares"}
														</NavLink>
													</li>
												) : null}
											</div>
										</ul>
									</div>
								</a>
								<a className="hoverCX menuhoverline">
									<NavLink activeClassName="menu-active" to="/experiencia-cliente">
										<span>{i18next.t("MainMenu.expCliente.self")}</span>
									</NavLink>
									<div class="hoverMenu hoverCXList">
										<ul>
											<li className="singleLine-menu-item">
												<NavLink
													to="/experiencia-cliente/net-promoter-score"
													activeClassName="activeDrop">
													{i18next.t("MainMenu.expCliente.cx")}
												</NavLink>
											</li>

											<li>
												<NavLink
													to="/experiencia-cliente/net-promoter-score#npsnumber"
													activeClassName="activeDrop">
													{i18next.t("MainMenu.expCliente.nps")}
												</NavLink>
											</li>
											<li>
												<NavLink to="/experiencia-cliente/logros" activeClassName="activeDrop">
													{i18next.t("MainMenu.expCliente.logrosCx")}
												</NavLink>
											</li>

											<li>
												<a
													href="https://grupoaeromexico.sharepoint.com/sites/AMPol%C3%ADticasYProcesos/Pages/Inicio.aspx"
													target="_blank"
													rel="noopener noreferrer">
													{i18next.t("MainMenu.expCliente.politicas")}
												</a>
											</li>
										</ul>
									</div>
								</a>
								<div className="noticiasMenu menuhoverline">
									<NavLink activeClassName="menu-active mt-0" to="/oportunidades-carrera">
										<span>{i18next.t("MainMenu.miExpAM.miDesem.vacantes")}</span>
									</NavLink>
								</div>

								<a className="MoreHover menuhoverline" activeClassName="menu-active">
									<a>
										<span>{i18next.t("MainMenu.mas.self")}</span>
									</a>
									<div class="hoverMenu hoverMenuMore">
										<ul>
											<li>
												<Link to="/buy-and-sell">{i18next.t("MainMenu.mas.triques")}</Link>
											</li>

											<li>
												<a
													// href="http://intranet.aeromexico.com.mx/"
													href="https://intranet.portal-aeromexico.com/"
													target="_blank"
													rel="noopener noreferrer">
													{i18next.t("MainMenu.mas.politicas")}
												</a>
											</li>

											<li>
												<a
													href={`${process.env.REACT_APP_SRC_URL}miam_docs/manuales/008ABR_Manual%20de%20convivencia_02.pdf`}
													target="_blank"
													rel="noopener noreferrer">
													{i18next.t("MainMenu.mas.manual")}
												</a>
											</li>
										</ul>
									</div>
								</a>
							</div>
							<div className="ExtraMenu">
								<div className="ProfileUser">
									<p className="WelcomeUser leading-tight">
										<span>
											{(localStorage.getItem("nivel") === "1" &&
												localStorage.getItem("grupo") !== "99 NSD - NO SINDICALIZADOS") ||
											localStorage.getItem("nivel") == "2" ||
											localStorage.getItem("nivel") === "3" ||
											localStorage.getItem("nivel") === "4" ||
											localStorage.getItem("nivel") === "5" ||
											localStorage.getItem("nivel") === "6" ||
											localStorage.getItem("nivel") === "7" ||
											localStorage.getItem("nivel") === "8" ||
											localStorage.getItem("nivel") === "9" ||
											localStorage.getItem("nivel") === "10" ||
											localStorage.getItem("nivel") === "11" ||
											localStorage.getItem("nivel") === "12" ||
											(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
												localStorage.getItem("email") === "nferri@aeromexico.com") ||
											(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
												localStorage.getItem("email") === "jsarvis@aeromexico.com") ? (
												<span className="leading-tight">
													{i18next.t("Profile.hola")}
													&nbsp;
													{!charging &&
														localStorage.getItem("nombre") && 
														localStorage.getItem("nombre").toLocaleLowerCase()+"!"														
													}
													{/* {!charging && this.state.nombre.toLowerCase() + "!"} */}
													{this.state.charging === true ? (
														<ReactPlaceholder
															showLoadingAnimation
															ready={this.state.ready}></ReactPlaceholder>
													) : (
														""
													)}
												</span>
											) : (
												""
											)}
										</span>

										<span>
											{localStorage.getItem("nivel") !== "1" &&
											localStorage.getItem("nivel") !== "2" &&
											localStorage.getItem("nivel") !== "3" &&
											localStorage.getItem("nivel") !== "4" &&
											localStorage.getItem("nivel") !== "5" &&
											localStorage.getItem("nivel") !== "6" &&
											localStorage.getItem("nivel") !== "7" &&
											localStorage.getItem("nivel") !== "8" &&
											localStorage.getItem("nivel") !== "9" &&
											localStorage.getItem("nivel") !== "10" &&
											localStorage.getItem("nivel") !== "11" &&
											localStorage.getItem("nivel") !== "12" ? (
												<span>{i18next.t("Profile.bienvenidoE")}</span>
											) : (
												""
											)}
										</span>
									</p>

									<div
										className={
											localStorage.getItem("alreadyRegistered") === "true"
												? `ImageUser ${localStorage.getItem("avatar")}`
												: `ImageUser`
										}
									/>
									<div className="ArrowUser" />
									<div className="MenuUserProfile">
										<div className="LittleArrow" />
										<div className="BreakWrap noiconsListMenu">
											{validateMenu() ? (
												<NavLink to="/recompensa-total/perfil/mi-compensaciones">
													{/* <img
														className="ImageMenu"
														src={core}
													/> */}
													<span>{i18next.t("Profile.recompensa")}</span>
												</NavLink>
											) : null}

											<a
												href="https://autoservicio.aeromexico.com/"
												target="_blank"
												rel="noopener noreferrer">
												<img className="ImageMenu" src={core} />
												<span>{i18next.t("Profile.core")}</span>
											</a>

											{/* {(localStorage.getItem('nivel') ===
												'1' &&
												localStorage.getItem('grupo') !=
													'99 NSD - NO SINDICALIZADOS') ||
											localStorage.getItem('nivel') ===
												'2' ||
											localStorage.getItem('nivel') ===
												'3' ||
											localStorage.getItem('nivel') ===
												'4' ||
											localStorage.getItem('nivel') ===
												'5' ||
											localStorage.getItem('nivel') ===
												'6' ||
											localStorage.getItem('nivel') ===
												'7' ||
											localStorage.getItem('nivel') ===
												'8' ||
											localStorage.getItem('nivel') ===
												'9' ||
											localStorage.getItem('nivel') ===
												'10' ||
											localStorage.getItem('nivel') ===
												'11' ||
											localStorage.getItem('nivel') ===
												'12' ||
											(localStorage.getItem('grupo') ===
												'99 NSD - NO SINDICALIZADOS' &&
												localStorage.getItem(
													'email'
												) ===
													'nferri@aeromexico.com') ||
											(localStorage.getItem('grupo') ===
												'99 NSD - NO SINDICALIZADOS' &&
												localStorage.getItem(
													'email'
												) ===
													'jsarvis@aeromexico.com') ? (
												<NavLink to="/recompensa-total/my-hr">
													<img
														className="ImageMenu"
														src={menuWork}
													/>
													<span>
														{i18next.t(
															'Profile.hr'
														)}
													</span>
												</NavLink>
											) : (
												''
											)} */}
										</div>

										<div className="BrNotifyWrapeakWrap noBluBack">
											<a
												onClick={() => this.openModal()}
												target="_blank"
												rel="noopener noreferrer">
												<img className="ImageMenu" src={core} />
												<span>{i18next.t("Profile.contactos")}</span>
											</a>
										</div>

										<div className="BrNotifyWrapeakWrap">
											<a href="/login" onClick={this.logout}>
												<img className="ImageMenu" src={logout} />
												<span>{i18next.t("Profile.cerrarSesion")}</span>
											</a>
										</div>
									</div>
								</div>
								<div className="NotificationMenu notify-container">
									<div className="NotifyWrap">
										{/* <p className="RedNumer" /> */}
										<img className="IconNotify displayNotifyDesktop" src={notify} />
										<img className="IconNotify displayNotifyApp" src={notifyApp} />
									</div>
									<Notifications />
								</div>
								<div className="NotificationMenu displaySearch">
									<NavLink to="/buscar" className="NotifyWrap">
										<img className="IconNotify" src={menuSearch} />
										<div className="SearchToolTip">
											<div className="LittleArrow" />
											<p className="TextSearch">{i18next.t("MainMenu.buscar")}</p>
										</div>
									</NavLink>
								</div>

								<div className="NotificationMenu notify-container Language">
									<div className="NotifyWrap" onClick={() => this.openModalLang()}>
										{/* (this.state.lang === "en") ? "EN" : "ES" */}
										<div className={`languageFlag ${i18next.language}`}></div>
									</div>
									{/*
                  <div className="container-notifications">
                  <div className="notification-column">
                    <a class="notification-item" onClick={() => this.onLangSelectedEvent('es')}> 
                      <p className="notification-text"> ES </p>
                    </a>
                    <a class="notification-item" onClick={() => this.onLangSelectedEvent('en')}>                   
                      <p className="notification-text"> EN </p>
                    </a>
                  </div>
                     </div>
                     */}
								</div>

								{/*  <div className="NotificationMenu displayLanguage">
                  <div className="LanguageWrap">
                  <input type="radio" name="language" 
                    value={"en"} 
                    checked={this.state.lang === "en"} 
                    onChange={this.onLangChangedEvent} />{" EN"}
                  <input type="radio" name="language" 
                      value={"es"}  
                      checked={this.state.lang === "es"} 
                    onChange={this.onLangChangedEvent} />{" ES"}
                  </div>
                     </div> */}
							</div>
						</div>
					</div>
				</div>

				{/* <Modal
					visible={this.state.langVisible}
					width="auto"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModalLang()} 
					className={` container-modal-notification ${this.state.langVisible ? "sendthiswayback" : ""}`}>
					<div className="selectLangugeModal menuModal"   >
						<div className="whiteModal">
							<div className="closeModal"  onClick={() => this.closeModalLang()}>
								<img src={close} alt="" />
							</div>
							<div className="padding" >
								<h4>
									<Trans i18nKey="Lang.selecciona"></Trans>
								</h4>
								<div className="gridFlags" >
									<a className="contFlag" onClick={() => this.onLangSelectedEvent("es")}>
										<div className="flags spanish"></div>
										<p>{i18next.t("Lang.es")}</p>
									</a>
									<a className="contFlag" onClick={() => this.onLangSelectedEvent("en")}>
										<div className="flags english"></div>
										<p>{i18next.t("Lang.en")}</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</Modal> */}

				<Modal
					visible={this.state.langVisible}
					width="auto"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModalLang()}
				>
					<div className="selectLangugeModal menuModal">
						<div className="whiteModal">
							<div className="closeModal" onClick={() => this.closeModalLang()}>
								<img src={close} alt="" />
							</div>
							<div className="padding">
								<h4>
									<Trans i18nKey="Lang.selecciona"></Trans>
								</h4>
								<div className="gridFlags">
									<a className="contFlag" onClick={() => this.onLangSelectedEvent("es")}>
										<div className="flags spanish"></div>
										<p>{i18next.t("Lang.es")}</p>
									</a>
									<a className="contFlag" onClick={() => this.onLangSelectedEvent("en")}>
										<div className="flags english"></div>
										<p>{i18next.t("Lang.en")}</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}
