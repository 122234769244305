import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
// internal imports
import VentaEspecialModal from '../Modales/modalventa';
import VentaEspecialModalInterna from '../Modales/modalventainterna';
// assets
import arrow from '../../../assets/images/arrow-blue.svg';
import avion from '../../../assets/images/benefits/download-bg-travel.jpg';
import iconDownloadManual from '../../../assets/images/download-icon.svg';
import close from '../../../assets/logo/close.svg';
import ReactMarkdown from 'react-markdown';

import { isValidURL } from '../../../utils/isValidEmail';
import DOMPurify from 'dompurify';

function SafeImage({ src, alt, className, fallback = 'fallback-image.png' }) {
	const sanitizedSrc = isValidURL(src) ? src : fallback; // Valida la URL de la imagen
	const sanitizedAlt = DOMPurify.sanitize(alt || ''); // Sanitiza el texto del atributo alt
  
	return <img src={sanitizedSrc} alt={sanitizedAlt} className={className} />;
  }

function VentaEspecialRegular({
	loading,
	entries,
	entries2,
	entries3,
	pageData,
	hideContainer
}) {
	const canShowCookie = sessionStorage.getItem('showVentaCookie');
	console.log('informacion para el componente de VE',pageData);
	console.log('traducciones', i18next);

	const MarkdownRenderer = ({ markdownText }) => {
		if (!markdownText) {return null;}
		const isMarkdown = markdownText.trim().startsWith('-') || markdownText.trim().startsWith('#');
	  return (
		  <>
			{isMarkdown ? (
			  <MarkdownRenderer markdownText={markdownText} />
			) : (
			  <p>{markdownText}</p>
			)}
		  </>
		);
	  };
	
	return (
		<>
			{!!canShowCookie && canShowCookie === 'TRUE' ? null : (
				<CookieConsent
					location="none"
					containerClasses="containerCoookie"
					buttonText={i18next.t(
						'Venta_especial_2020.modal_intro.button'
					)}
					cookieName="modalVentaEspecial"
					// onAccept={() => {alert("yay!")}}
					buttonClasses="acceptVentaEspecialButton"
					debug="true"
					disableButtonStyles="true"
					onAccept={() => {
						hideContainer();
						sessionStorage.setItem('showVentaCookie', 'TRUE');
					}}
					style={{
						background: 'rgba(0,0,0,.8)',
						position: 'fixed',
						top: '0%',
						width: '100%',
						height: '100vh',
						left: '0'
					}}
					expires={365}
				>
					<div className="modalWhiteWelcome modalTextTriques modalventaEspecialCookie">
						<p className="title">
							{/* <Trans i18nKey="VentaEspecial.title"></Trans> */}
							{pageData.data.attributes.alert_title}
						</p>
						<p
							className="subtitle mt-3"
							style={{ fontSize: '16px', lineHeight: 1.6 }}
						>
							{/* {i18next.t('VentaEspecial.modalDescription')} */}
							<MarkdownRenderer markdownText={pageData.data.attributes.alert_description} />
						</p>
					</div>
				</CookieConsent>
			)}

			<div
				className="modal fade modal-xl"
				id="modalLearnFamily"
				tabindex="-1"
				role="dialog"
				aria-labelledby="modal360Title"
				aria-hidden="true"
			>
				<div
					className="modal-dialog modal-dialog-centered modal-xl"
					role="document"
				>
					<div className="modal-content w-100 modalVentaEspecial p-5">
						<div className="modalRegistraFamiliaAhora">
							<a class="closeModal close" data-dismiss="modal">
								<img src={close} alt='cerrado'></img>
							</a>
							<div className="wrapperDivName">
								{!loading &&
									entries &&
									entries.map((legalWindowModalFamily) => (
										<div
											className="divtravelerFamilia"
											dangerouslySetInnerHTML={{
												__html: legalWindowModalFamily
													.fields.modalFamiliaMas
											}}
										/>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="BannerEspecialDestinos">
				<p
					// dangerouslySetInnerHTML={{
					// 	__html: i18next.t('VentaEspecial.maintitle')
					// }}
					style={{ fontSize: '20px' }}
				>
					<MarkdownRenderer markdownText={pageData.data.attributes.banner} />
				</p>
				<div className="EventaEspecialCambios"></div>
			</div>

			<div className="wrapperFlexVentaEspecial">
				<div className="leftVentaEspecial">
					<div className="gridColumnsEspecial">
						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.one_image.url}
								alt={pageData.data.attributes.one_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.one_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.one_description} />
							</p>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.two_image.url}
								alt={pageData.data.attributes.two_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.two_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.two_description} />
							</p>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.three_image.url}
								alt={pageData.data.attributes.three_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.three_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.three_description} />
							</p>
							<a
								href="#data"
								data-toggle="modal"
								data-target="#modalLearnFamily"
								className="LearnmoreFamily"
							>
								{i18next.t('VentaEspecial.textlink')}
							</a>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.four_image.url}
								alt={pageData.data.attributes.four_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.four_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.four_description} />
							</p>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.five_image.url}
								alt={pageData.data.attributes.five_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.five_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.five_description} />
							</p>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.six_image.url}
								alt={pageData.data.attributes.six_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.six_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.six_description} />
							</p>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.seven_image.url}
								alt={pageData.data.attributes.seven_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.seven_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.seven_description} />
							</p>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.eight_image.url}
								alt={pageData.data.attributes.eight_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.eight_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.eight_description} />
							</p>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.nine_image.url}
								alt={pageData.data.attributes.nine_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.nine_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.nine_description} />
							</p>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.ten_image.url}
								alt={pageData.data.attributes.ten_title}
								className="imgVenta"
							/>
							<p className="titleVenta">{pageData.data.attributes.ten_title}</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.ten_description} />
							</p>
							<a
								href={i18next.t('VentaEspecial.embargo')}
								target="_blank"
								rel="noopener noreferrer"
								className="LearnmoreFamily"
							>
								{i18next.t('VentaEspecial.textlink')}
							</a>
						</div>

						<div className="optionGrid">
							<SafeImage
								src={pageData.data.attributes.eleven_image.url}
								alt={pageData.data.attributes.eleven_title}
								className="imgVenta"
							/>
							<p className="titleVenta">
								{pageData.data.attributes.eleven_title}
							</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText={pageData.data.attributes.eleven_description} />
							</p>
						</div>
						<div className="optionGrid">
							<img
								src="https://dyn-miam-cde-pd.miaeromexico.com/cadmin/2024/icon_part.png"
								className="imgVenta"
								alt="icono compañero de viaje"
							/>
							<p className="titleVenta">
								Compañero de viaje
							</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText="Es cualquier persona con o sin parentesco familiar con la que puedes viajar usando tus boletos de la venta especial. No requiere registro en CORE." />
							</p>
							<a
								href="https://dyn-miam-cde-pd.miaeromexico.com/cadmin/2024/COMPANERO_DE_VIAJE_VENTA_ESPECIAL.pdf"
								target="_blank"
								rel="noopener noreferrer"
								className="LearnmoreFamily"
							>
								{i18next.t('VentaEspecial.textlink')}
							</a>
						</div>
						<div className="optionGrid">
							<img
								src="https://dyn-miam-cde-pd.miaeromexico.com/cadmin/2024/icon_upgrade.png"
								className="imgVenta"
								alt="icon upgrade"
							/>
							<p className="titleVenta">
								Upgrade al cierre del vuelo
							</p>
							<p className="subTitleVenta">
								<MarkdownRenderer markdownText="Al realizar check-in en web o en app de Aeroméxico automáticamente estarás en lista de espera para ascensos a Clase Premier." />
							</p>
							<a
								href="https://dyn-miam-cde-pd.miaeromexico.com/cadmin/2024/UPGRADE_VENTA_ESPECIAL.pdf"
								target="_blank"
								rel="noopener noreferrer"
								className="LearnmoreFamily"
							>
								{i18next.t('VentaEspecial.textlink')}
							</a>
						</div>
					</div>

					<div className="termsVentaEspecialConsulta">
						<Trans i18nKey="VentaEspecial.termstext">
							terminos <span>{pageData.data.attributes.contact}</span>
						</Trans>
					</div>
				</div>

				<div className="rightVentaEspecial">
					<div className="stickyElementVenta ventaespecialDestinosModales">
						<div className="tittleHeaderAccede">
							<p className="titleAccede">
								{i18next.t('VentaEspecial.accede')}
							</p>
						</div>

						<div
							className="boletosEmbargo"
							id="noclasesBoletosEmbargo"
						>
							<VentaEspecialModal />
							<VentaEspecialModalInterna />
						</div>
					</div>

					<div className="stickyElementVenta ventaespecialLinc">
						<div
							className="boletosEmbargo"
							id="noclasesBoletosEmbargo"
						>
							<p
								className="linealincTitulo"
								dangerouslySetInnerHTML={{
									__html: i18next.t(
										'VentaEspecial.linctitulo'
									)
								}}
							>
								
							</p>
							<div className="lineaLincImg"></div>
							<p className="schedule">
								<Trans i18nKey="VentaEspecial.horario">
									horario <span>{i18next.t('1')}</span>
								</Trans>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

const BenefitCardBG = (props) => (
	<div
		className="benefits-big-card bg-container-card travel"
		style={{
			backgroundImage: `${props.gradient}, url(${props.image})`,
			backgroundSize: `${props.size}`,
			backgroundPositionY: `${props.positionY}`,
			backgroundPositionX: `${props.positionX}`
		}}
	>
		<p
			className="moreTravelBenefits__card__text"
			style={{ color: `${props.color}` }}
		>
			{props.content}
		</p>
		<h5
			className="moreTravelBenefits__card__title"
			style={{ color: `${props.color}` }}
			dangerouslySetInnerHTML={{ __html: props.title }}
		/>

		<Link className="link-travel-benefits" to="#">
			<div className="moreTravel">
				<h6
					className="moreTravelBenefits__card__more"
					style={{ color: `${props.color}` }}
				>
					View more{' '}
				</h6>
				<img className="blue-arrow" src={arrow} alt="blue-arrow" />{' '}
			</div>
		</Link>
	</div>
);
const DownloadManual = () => (
	<div>
		{/*Nivel L1-L8*/}
		{/*Nivel L1-L8*/}
		{/*Nivel L1-L8*/}
		{localStorage.getItem('nivel') === '1' ||
		localStorage.getItem('nivel') === '2' ||
		localStorage.getItem('nivel') === '3' ||
		localStorage.getItem('nivel') === '4' ||
		localStorage.getItem('nivel') === '5' ||
		localStorage.getItem('nivel') === '6' ||
		localStorage.getItem('nivel') === '7' ||
		localStorage.getItem('nivel') === '8' ? (
			<div className="button-download travel">
				<a
					href="https://1drv.ms/b/s!Al_AF6hMZ6JYqck70mHM1OzkFdIfbw"
					target="_blank"
					className="download-button"
					style={{
						backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.2), rgba(11, 35, 67,0.2)),url(${avion})`
					}}
					// href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EXvcutlW0-lFhfQ4ZbBbjywBZsaGXWt2NB4ratpHRr2y4Q?e=svA2b7"
				>
					<div className="container-download">
						<div class="icondownload">
							<img src={iconDownloadManual} />
						</div>
						{/* <i className="fa fa-download" /> */}
						<div className="down-benefits">
							<h3 className="download-benefits">
								{i18next.t('VentaEspecial.manualTitle')}
							</h3>
							<h5 className="download-bnf">
								{i18next.t('VentaEspecial.manualDesc')}
							</h5>
						</div>
					</div>
					<h2 className="download">
						{i18next.t('Sostenib.informes.descargar')}
					</h2>
				</a>
			</div>
		) : (
			''
		)}

		{/*Nivel L9*/}
		{/*Nivel L9*/}
		{/*Nivel L9*/}
		{localStorage.getItem('nivel') === '9' ? (
			<div className="button-download travel">
				<a
					href="https://1drv.ms/b/s!Al_AF6hMZ6JYqck70mHM1OzkFdIfbw"
					target="_blank"
					className="download-button"
					style={{
						backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.2), rgba(11, 35, 67,0.2)),url(${avion})`
					}}
					// href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/ETy-YkxcJS9Ioio8zNofHWgBOfWLYYvSijMFn8T-KK-Pyg?e=UHyL64"
				>
					<div className="container-download">
						<div class="icondownload">
							<img src={iconDownloadManual} />
						</div>
						{/* <i className="fa fa-download" /> */}
						<div className="down-benefits">
							<h3 className="download-benefits">
								{i18next.t('VentaEspecial.manuall9Title')}
							</h3>
							<h5 className="download-bnf">
								{i18next.t('VentaEspecial.manualDesc')}
							</h5>
						</div>
					</div>
					<h2 className="download">
						{i18next.t('Sostenib.informes.descargar')}
					</h2>
				</a>
			</div>
		) : (
			''
		)}

		{/*Nivel L10-L12*/}
		{/*Nivel L10-L12*/}
		{/*Nivel L10-L12*/}
		{localStorage.getItem('nivel') === '10' ||
		localStorage.getItem('nivel') === '11' ||
		localStorage.getItem('nivel') === '12' ? (
			<div className="button-download travel">
				<a
					href="https://1drv.ms/b/s!Al_AF6hMZ6JYqck70mHM1OzkFdIfbw"
					target="_blank"
					className="download-button"
					style={{
						backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.2), rgba(11, 35, 67,0.2)),url(${avion})`
					}}
					// href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EU3jsFJLm3dCszhK2HJAXBQBfqfsbyUTa5UEQU43tSns2Q?e=62E3g1"
				>
					<div className="container-download">
						<div class="icondownload">
							<img src={iconDownloadManual} />
						</div>
						{/* <i className="fa fa-download" /> */}
						<div className="down-benefits">
							<h3 className="download-benefits">
								{i18next.t('VentaEspecial.manuall10Title')}
							</h3>
							<h5 className="download-bnf">
								{i18next.t('VentaEspecial.manualDesc')}
							</h5>
						</div>
					</div>
					<h2 className="download">
						{i18next.t('Sostenib.informes.descargar')}
					</h2>
				</a>
			</div>
		) : (
			''
		)}
	</div>
);

export default VentaEspecialRegular;
