import React, { Component } from 'react';
// import Aside from "../AsideProfile";
import * as contentful from '../../lib/contentful';

// import "./css/form.css";

function validate(
	name,
	email,
	employee_number,
	actual_boss,
	actual_job,
	cv,
	pregunta_abierta
) {
	// true means invalid, so our conditions got reversed
	return {
		name: name.length === 0,
		email: email.length === 0,
		employee_number: employee_number.length === 0,
		actual_job: actual_job.length === 0,
		actual_boss: actual_boss.length === 0,
		cv: cv.length === 0,
		pregunta_abierta: pregunta_abierta.length === 0
	};
}

export default class Form extends Component {
	state = {
		name: '',
		error: false,
		charging: true,
		data: null,
		succes: false,
		email: '',
		password: '',
		name: '',
		email: '',
		employee_number: '',
		actual_boss: '',
		actual_job: '',
		pregunta_abierta: '',
		cv: '',
		loadingEntry: true,
		touched: {
			email: false,
			password: false
		}
	};

	componentDidMount() {
		this.fetchEntry();
		this.setData();
	}
	fetchEntry = () => {
		contentful.client
			.getEntry(this.props.match.params.jobId)
			.then(response => response)
			.then(data => {
				this.setState({
					loadingEntry: false,
					entry: data
				});
			})
			.catch(error => {
				this.setState({
					loadingEntry: true,
					errorEntry: error
				});
			})
			.catch(err => console.error(err));
	};
	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
			succes: false
		});
	};
	handleCv = e => {
		this.setState({
			cv: e.target.files[0],
			fileName: e.target.files[0].name,
			succes: false
		});
	};

	handleBlur = field => evt => {
		this.setState({
			touched: { ...this.state.touched, [field]: true }
		});
	};

	setData = () => {
		if (
			localStorage.getItem('nombre') !== null &&
			localStorage.getItem('apellidoMaterno') &&
			localStorage.getItem('apellidoMaterno')
		) {
			this.setState({
				name:
					localStorage.getItem('nombre').toLowerCase() +
					' ' +
					localStorage.getItem('apellidoPaterno').toLowerCase() +
					' ' +
					localStorage.getItem('apellidoMaterno').toLowerCase()
			});
		}
		if (localStorage.getItem('email') !== null) {
			this.setState({
				email: localStorage.getItem('email')
			});
		}
		if (localStorage.getItem('noEmpleado') !== null) {
			this.setState({
				employee_number: localStorage.getItem('noEmpleado')
			});
		}
		if (localStorage.getItem('noEmpleado') !== null) {
			this.setState({
				employee_number: localStorage.getItem('noEmpleado')
			});
		}
		if (localStorage.getItem('posicion') !== null) {
			this.setState({
				actual_job: localStorage.getItem('posicion').toLowerCase()
			});
		}
	};

	canBeSubmitted() {
		const {
			name,
			email,
			employee_number,
			actual_boss,
			actual_job,
			cv,
			pregunta_abierta
		} = this.state;
		const errors = validate(
			name,
			email,
			employee_number,
			actual_boss,
			actual_job,
			cv,
			pregunta_abierta
		);
		const isDisabled = Object.keys(errors).some(x => errors[x]);
		return !isDisabled;
	}

	handleSubmit = e => {
		e.preventDefault();
		if (!this.canBeSubmitted()) {
			return;
		}
		alert('Sistema bloqueado');
		// const URL = 'http://localhost:5000/api/v1/send-email';
		// const URL2 =
		// 	'https://miaeromexico-jobs.herokuapp.com/api/v1/send-email';

		// let formData = new FormData();

		// formData.append('name', this.state.name);
		// formData.append('email', this.state.email);
		// formData.append('employee_number', this.state.employee_number);
		// formData.append('actual_boss', this.state.actual_boss);
		// formData.append('pregunta_abierta', this.state.pregunta_abierta);
		// formData.append('actual_job', this.state.actual_job);
		// formData.append(
		// 	'recruiter',
		// 	this.state.entry.fields.correoDelReclutador
		// );
		// formData.append('vacante', this.state.entry.fields.nombreVacante);
		// formData.append('fileName', this.state.fileName);
		// formData.append('cv', this.state.cv);

		// console.log(formData);
		// fetch(URL2, {
		// 	method: 'POST',
		// 	body: formData
		// })
		// 	.then(data => data.json())
		// 	.then(data => {
		// 		console.log(data);
		// 		this.setState({
		// 			error: false,
		// 			data,
		// 			charging: false,
		// 			succes: true
		// 		});
		// 	})
		// 	.then(() => document.getElementById('form-jobs').reset())
		// 	.catch(error => {
		// 		console.log(error);
		// 		this.setState({
		// 			error,
		// 			charging: true
		// 		});
		// 	});
	};

	render() {
		const {
			charging,
			error,
			succes,
			name,
			email,
			employee_number,
			actual_boss,
			actual_job,
			cv,
			pregunta_abierta
		} = this.state;
		const errors = validate(
			name,
			email,
			employee_number,
			actual_boss,
			actual_job,
			cv,
			pregunta_abierta
		);

		const isDisabled = Object.keys(errors).some(x => errors[x]);

		const shouldMarkError = field => {
			const hasError = errors[field];
			const shouldShow = this.state.touched[field];

			return hasError ? shouldShow : false;
		};
		return (
			<div className="full-container">
				<div className="contProfileMenu">
					<div class="profileContainers" id="profileMenu"></div>
				</div>
				<div class="vacantesWrapper form-container">
					<div className="wrapper-title-form">
						<h1 className="form-title">Formulario de vacantes</h1>
						{/* <h1 className="form-title">{this.props.data.fields.validoHasta}</h1> */}
					</div>
					<div className="form-wrapper">
						<form
							id="form-jobs"
							onSubmit={this.handleSubmit}
							encType="multipart/form-data"
						>
							<fieldset>
								<div className="control-form control-name">
									<div className="label-form">
										<label for="name">Nombre</label>
									</div>
									<div className="input-form input-name">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur('name')}
											className={
												shouldMarkError('name')
													? 'input-field error'
													: 'input-field'
											}
											name="name"
											id="name"
											value={name}
											required="true"
											type="text"
											placeholder="Nombre"
										/>
									</div>
								</div>
								<div className="control-form control-email">
									<div className="label-form">
										<label for="email">Email</label>
									</div>
									<div className="input-form input-email">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur('email')}
											className={
												shouldMarkError('email')
													? 'input-field error'
													: 'input-field'
											}
											name="email"
											id="email"
											value={email}
											required="true"
											type="email"
											placeholder="Email"
										/>
									</div>
								</div>
								<div className="control-form control-employee">
									<div className="label-form">
										<label for="employee_number">
											No. empleado
										</label>
									</div>
									<div className="input-form input-employee">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur(
												'employee_number'
											)}
											className={
												shouldMarkError(
													'employee_number'
												)
													? 'input-field error'
													: 'input-field'
											}
											name="employee_number"
											id="employee_number"
											value={employee_number}
											required="true"
											type="number"
											placeholder="Número de empleado"
										/>
									</div>
								</div>
								<div className="control-form control-boss">
									<div className="label-form">
										<label for="actual_boss">
											Jefe inmediato
										</label>
									</div>
									<div className="input-form input-boss">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur(
												'actual_boss'
											)}
											className={
												shouldMarkError('actual_boss')
													? 'input-field error'
													: 'input-field'
											}
											name="actual_boss"
											id="actual_boss"
											value={actual_boss}
											required="true"
											type="text"
											placeholder="Jefe inmediato"
										/>
									</div>
								</div>
								<div className="control-form control-name">
									<div className="label-form">
										<label for="actual_job">
											Posición actual
										</label>
									</div>
									<div className="input-form input-actual-job">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur(
												'actual_job'
											)}
											className={
												shouldMarkError('actual_job')
													? 'input-field error'
													: 'input-field'
											}
											name="actual_job"
											id="actual_job"
											value={actual_job}
											required="true"
											type="text"
											placeholder="Posición actual"
										/>
									</div>
								</div>

								{/* PLACEHOLDER */}
								<div className="control-form control-pregunta">
									<div className="label-form">
										<label for="pregunta_abierta">
											¿Por qué te interesa aplicar a esta
											vacante?
										</label>
									</div>
									<div className="input-form input-pregunta">
										<textarea
											onChange={this.handleChange}
											onBlur={this.handleBlur(
												'pregunta_abierta'
											)}
											className={
												shouldMarkError(
													'pregunta_abierta'
												)
													? 'input-field error'
													: 'input-field'
											}
											name="pregunta_abierta"
											id="pregunta_abierta"
											required="true"
											type="text"
											placeholder="Escribe tu respuesta"
										/>
									</div>
								</div>

								<div className="control-form control-cv">
									<div className="label-form custom-file-upload">
										<label for="cv">Adjunta tu CV</label>
									</div>
									<div className="input-form input-CV">
										<p className="uploadyourfile">
											Selecciona un archivo
										</p>
										<input
											onChange={this.handleCv}
											onBlur={this.handleBlur('cv')}
											className={
												shouldMarkError('cv')
													? 'input-field error'
													: 'input-field'
											}
											name="cv"
											id="cv"
											required="true"
											type="file"
											placeholder="CV"
										/>
									</div>
								</div>
							</fieldset>

							<button
								disabled={isDisabled}
								id="btn-job"
								className="btn-job"
								type="submit"
							>
								Enviar
							</button>
							{!charging && succes && (
								<p className="succes-msg-job">
									Su aplicación ha sido enviada exitosamente{' '}
								</p>
							)}
						</form>
					</div>
				</div>
			</div>
		);
	}
}
