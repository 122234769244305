import ReactGA from "react-ga";

export const initializeAnalytics = () => {
    ReactGA.initialize("UA-138315711-1");
    ReactGA.set();
    trackPageViews();
};

export const trackPageViews = () => {
    const pages = [
        "/login",
        "/inicio",
        "/nosotros",
        "/recompensa-total/perfil/venta-especial",
        "/defendamos-nuestro-cielo",
        "/articles/",
        "/nosotros/plan-de-vuelo",
        "/nosotros/responsabilidad-social",
        "/nosotros/jca-aeromexico-delta",
        "/nosotros/gam-talks",
        "/seguridad",
        "/oportunidades-carrera",
        "/recompensa-total/my-hr",
        "/recompensa-total/viajes-aeromexico",
        "/experiencia-cliente",
        "/experiencia-cliente/logros",
        "/nosotros/responsabilidad-social/informe-sostenibilidad",
        "/recompensa-total/perfil/mi-compensaciones",
        "/recompensa-total/perfil/my-travel-benefits",
        "/recompensa-total/perfil/mis-beneficios",
        "/recompensa-total/perfil",
        "/seguridad#gamreport",
        "/recompensa-total/mis-beneficios/descuentos/salud-y-belleza",
        "/recompensa-total/mis-beneficios/descuentos/entretenimiento",
        "/recompensa-total/mis-beneficios/descuentos/gimnasios",
        "/recompensa-total/mis-beneficios/descuentos/de-compras",
        "/recompensa-total/mis-beneficios/descuentos/educacion",
        "/recompensa-total/mis-beneficios/descuentos/restaurantes",
        "/recompensa-total/mis-beneficios/descuentos/automoviles",
        "/recompensa-total/mis-beneficios/descuentos/servicios",
        "/recompensa-total/mis-beneficios/descuentos/guarderias",
        "/articles/5I59C1oQqeZRjBANfYJCYP",
        "/experiencia-cliente/cx-evolution",
        "/buy-and-sell",
    ];

    pages.forEach((page) => ReactGA.pageview(page));
};

export const trackModalViews = () => {
    const modals = [
        "/inicio",
        "/recompensa-total/perfil/venta-especial",
    ];

    modals.forEach((modal) => ReactGA.modalview(modal));
};

export const setUserAnalytics = () => {
    if (localStorage.getItem("email")) {
        ReactGA.set({ userId: localStorage.getItem("email") });
        ReactGA.set({ dimension1: localStorage.getItem("email") });
    }

    const dimension2 = localStorage.getItem("nivel") ? "NSD" : "SND";
    ReactGA.set({ dimension2 });
};

export const trackCustomEvents = () => {
    ReactGA.event({
        category: "Log In",
        action: "User logged into the site",
    });
};
