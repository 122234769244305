import React, { Component } from 'react';
import './css/customerexp.css';
import ReactPlayer from 'react-player';
import close from '../../assets/logo/close.svg';
import wave from '../../assets/wave.svg';
import flecha from '../../assets/images/slider/arrow-blue.svg';
import LogrosCard from './logrosCard';
import Scrollmenu from '../Header/scrollmenu';
import { Parallax } from 'react-parallax';
import Header from '../../components/Header';

import Index from '../Footer/index';
import EquipajeIMG from "../../assets/cxsite/newicons/equipaje.svg";
// import BoletoIMG from "../../assets/cxsite/boleto.svg";
import BoletoIMG from "../../assets/cxsite/newicons/reembolso.svg";
// import CancelacionIMG from "../../assets/cxsite/retro.svg";
import CancelacionIMG from "../../assets/cxsite/newicons/cancelacion.svg";
// import DemoraIMG from "../../assets/cxsite/pc.svg";
import DemoraIMG from "../../assets/cxsite/newicons/demora.svg";
// import FallaIMG from "../../assets/cxsite/serv.svg";
import FallaIMG from "../../assets/cxsite/newicons/portal.svg";
import { 
	Element
} from 'react-scroll';
import { animateScroll as scroll} from "react-scroll";
import Fade from 'react-reveal/Fade';
import TitleCXEvo from './tittlecx-landing';
import * as contentful from '../../lib/contentful';
import Scrollmenuours from "../Header/scrollmenuours";


import {
	ResponsiveContainer,
	ComposedChart,
	Line,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend
} from 'recharts';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import initLang from '../../utils/initLang';
import getLocale from '../../utils/getLocale';
const meses = [
	'Ene',
	'Feb',
	'Mar',
	'Abr',
	'May',
	'Jun',
	'Jul',
	'Ago',
	'Sep',
	'Oct',
	'Nov',
	'Dic'
];

const months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sept',
	'Oct',
	'Nov',
	'Dec'
];

var dataGraph = [];

function get2020InfoOfItem(label) {
	return dataGraph.find((x) => x.name === label).m2022;
}

function get2019InfoOfItem(label) {
	return dataGraph.find((x) => x.name === label).m2021;
}

function getTargetInfoOfItem(label) {
	return dataGraph.find((x) => x.name === label).Target;
}

function CustomTooltip({ payload, label, active }) {
	if (active) {
		return (
			<div className="recharts-default-tooltip">
				<p className="recharts-tooltip-label">{`${label}`}</p>
				<ul className="recharts-tooltip-item-list">
					<li className="recharts-tooltip-item m2019">
						<span class="recharts-tooltip-item-name">2024</span> :{' '}
						<span class="recharts-tooltip-item-value">
							{get2019InfoOfItem(label)}
						</span>
					</li>
					<li className="recharts-tooltip-item m2021">
						<span class="recharts-tooltip-item-name">2025</span> :{' '}
						<span class="recharts-tooltip-item-value">
							{get2020InfoOfItem(label)}
						</span>
					</li>
					<li className="recharts-tooltip-item target">
						<span class="recharts-tooltip-item-name">Target</span> :{' '}
						<span class="recharts-tooltip-item-value">
							{getTargetInfoOfItem(label)}
						</span>
					</li>
				</ul>
			</div>
		);
	}
	return null;
}

// Offset all anchors by -60 to account for a fixed header
// and scroll more quickly than the default 400ms
/* configureAnchors({
	offset: -100,
	scrollDuration: 200,
	keepLastAnchorHash: true
}); */

export default class SliderSwiper extends Component {
	static jsfiddleUrl = 'https://jsfiddle.net/alidingling/q5atk5jr/';

	constructor() {
		super();
		this.state = {
			modalIsShow: false,
			loading: true,
			error: null,
			entries: null,
			lang: ''
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount =()=>{
		initLang(this);
		document.addEventListener("keydown", this.escFunction, false);
		window.addEventListener("scroll", this.handleScroll);
		setTimeout(() => this.setState({ loading: false }), 1500);
		this.handleHashNavigation();
		

		initLang(this);
		this.setDataGraph();
		this.fetchEntries();
		scroll.scrollToTop();
	}

	componentDidUpdate = (prevProps)=> {
		if (this.props.location !== prevProps.location) {
		  this.handleHashNavigation();
		}
	  }
	
	  handleHashNavigation() {
		const location = window.location;
		 
		if (location.hash) {
		  const element = document.getElementById(location.hash.substring(1));
		  if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		  }
		}
	  }
	
	  handleScroll = (event) => {
		const scrollTop = window.pageYOffset;
		if (scrollTop > 35) {
		  this.setState({ hasScrolled: true });
		} else {
		  this.setState({ hasScrolled: false });
		}
	  };
	
	


	monthName(num) {
		if (this.state.lang === 'es') return meses[num];
		else return months[num];
	}

	setDataGraph() {
		dataGraph = [
			{
				name: this.monthName(0),
				m2021: 24.41,
				m2022: 41.60,
				Target: 39.54
			},
			{
				name: this.monthName(1),
				m2021: 43.31,
				m2022: null,
				Target: 41.97
			},
			{
				name: this.monthName(2),
				m2021: 37.91,
				m2022: null,
				Target: 39.29
			},
			{
				name: this.monthName(3),
				m2021: 41.18,
				m2022: null,
				Target: 40.90
			},
			{
				name: this.monthName(4),
				m2021: 37.15,
				m2022: null,
				Target: 38.21
			},
			{
				name: this.monthName(5),
				m2021: 42.22,
				m2022: null,
				Target: 40.31
			},
			{
				name: this.monthName(6),
				m2021: 38.43,
				m2022: null,
				Target: 41.22
			},
			{
				name: this.monthName(7),
				m2021: 45.13,
				m2022: null,
				Target: 43.75
			},
			{
				name: this.monthName(8),
				m2021: 48.49,
				m2022: null,
				Target: 44.27
			},
			{
				name: this.monthName(9),
				m2021: 51.36,
				m2022: null,
				Target: 45.81
			},
			{
				name: this.monthName(10),
				m2021: 44.84,
				m2022: null,
				Target: 40.69
			},
			{
				name: this.monthName(11),
				m2021: 45.14,
				m2022: null,
				Target: 39.04
			}
			/*{
        name: 'YTD', m2019: 32.38, m2021: 35.60, Target: 33.13,
      },*/
		];
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'stateOfAirline',
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	handleClick = (e) => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	render() {
		const { loading, entries, error } = this.state;
		return (
			// <LoadingScreen
			//   loading={loading}
			//   bgColor="#f5f5f5"
			//   spinnerColor="#007dc3"
			//   textColor="#0b2343"
			//   //logoSrc={avion}
			//   text="Cargando tu experiencia"
			// >

			<div className="customerExperienceSite">
				{/* <Header/> */}
				{/* BANNER OCULTADO */}
				{/* <LiveTheExperience /> */}

				{this.state.modalIsShow === true ? (
					<div className="flightVideoModal">
						<div
							className="videoModalWindow"
							ref={this.setWrapperRef}
						>
							<div
								className="closeModal"
								onClick={this.showAlertModal}
							>
								<img src={close} alt='cerrar'></img>
							</div>

							<ReactPlayer
								url="https://aeromexico-2.wistia.com/medias/7m9px8tnp4"
								playing="false"
								width="100%"
								height="100%"
								controls="true"
								volume=".1"
							/>
						</div>
					</div>
				) : (
					''
				)}

				<Element name="test1" className="element header">
					<Scrollmenu />
					<Parallax
						className="parallax-back"
						bgImage={require('../../assets/cxsite/customer.jpg')}
						bgImageAlt="the cat"
						strength={-350}
						bgImageStyle={{ height: '950px' }}
					>
						<div className="HeaderFirst">
							<div id={'inicioCX'}>
								<div></div>
							</div>
							<Fade big>
								<div className="midWrap">
									<div className="logo"></div>
									<h2 className="sizeCont">
										<Trans i18nKey="ClienteEx.main"></Trans>
									</h2>
									<p> {i18next.t('ClienteEx.sub')} </p>
									<p> {i18next.t('ClienteEx.sub2')} </p>
								</div>
							</Fade>
							<div className="imagebgCX"></div>
							<div className="imagebgHug"></div>
						</div>
					</Parallax>
					<div className="wave" aria-hidden="true">
						<img className="svgWave" src={wave} alt="wave"></img>
					</div>
				</Element>

				<div className="LiveTheExperienceSmallContainer">
					<div className="LiveTheExperienceSmall">
						<div class="imgWrapperCX">
							<div className="logoCXBanner"></div>
						</div>
					{/* 	<div class="textWrapperCX">
							<p>
								<Trans i18nKey="ClienteEx.banner.title"></Trans>
							</p>
							<p>{i18next.t('ClienteEx.banner.sub')}</p>
							<p>{i18next.t('ClienteEx.banner.trans')}</p>
						</div> */}
						<a href="/experiencia-cliente/cx-evolution">
							{i18next.t('ClienteEx.banner.conoce')}
						</a>
					</div>
				</div>

				<div id={'netpromoter'}>
					<div></div>
				</div>
				<div className="NPS-number">
					<div className="NPSWrapper">
						<div className="wrapperMid">
							<Element name="test2" className="element">
								<div class="npsNumber">
									<h2>{i18next.t('ClienteEx.net.title')}</h2>
									<p>{i18next.t('ClienteEx.net.sub')}</p>
									<div
										onClick={this.showAlertModal}
										className="special-item ourhovera block rounded-full"
									>
										<span className="mt-1 flex flex-nowrap items-center justify-center whitespace-nowrap">
											<a>
												{i18next.t(
													'ClienteEx.net.boton'
												)}
											</a>
											<img
												className="blue-arrow"
												src={flecha}
												alt="blue-arrow"
											/>
										</span>
									</div>
									<div className="wrapperCxMeaning">
										<div className="cxMidWrapper">
											<div className="wrapperRight">
												<p className="title">
													{i18next.t(
														'ClienteEx.net.como'
													)}
												</p>
												<p className="subtitleCx">
													{i18next.t(
														'ClienteEx.net.sub2'
													)}
												</p>
												<div className="facesCxWrapper">
													<div className="faceWrapper">
														<div className="imgWrapper"></div>
														<p>
															<Trans i18nKey="ClienteEx.net.detrac">
																detrac{' '}
																<span></span>
															</Trans>
														</p>
														{/* <p>1, 2 o 3</p> */}
													</div>

													<div className="faceWrapper">
														<div className="imgWrapper"></div>
														<p>
															{i18next.t(
																'ClienteEx.net.neutros'
															)}
															: <span>4</span>
														</p>
														{/* <p>4</p> */}
													</div>

													<div className="faceWrapper">
														<div className="imgWrapper"></div>
														<p>
															{i18next.t(
																'ClienteEx.net.promo'
															)}
															: <span>5</span>
														</p>
														{/* <p>5</p> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div id={'npsnumber'}>
										<div></div>
									</div>
								</div>
							</Element>

							{/* Grafica de Customer Experience NPS */}
							<Element name="test3" className="element">
								<div className="grapghCX">
									<div>
										{!loading &&
											entries &&
											entries.map((stateAriline) => (
												<div key={stateAriline.sys.id}>
													<h2>
														{
															stateAriline.fields
																.mesDeNps
														}
														:{' '}
														<span>
															{
																stateAriline
																	.fields
																	.npsNumber
															}
														</span>
													</h2>
													<p>
														{
															stateAriline.fields
																.introANps
														}
													</p>
												</div>
											))}
									</div>

									<div className="graphResponsive">
										<div
											style={{
												width: '100%',
												height: 300
											}}
										>
											<ResponsiveContainer>
												<ComposedChart
													width={500}
													height={400}
													data={dataGraph}
													margin={{
														top: 20,
														right: 20,
														bottom: 20,
														left: 20
													}}
												>
													<CartesianGrid stroke="#f5f5f5" />
													<XAxis dataKey="name" />
													<YAxis />
													<Tooltip
														content={
															<CustomTooltip />
														}
													/>
													<Legend />
													<Bar
														name="2024"
														type="monotone"
														dataKey="m2021"
														barSize={20}
														fill="#d6d6d6"
														radius={[
															10, 10, 10, 10
														]}
													/>
													<Bar
														name="2025"
														type="monotone"
														dataKey="m2022"
														barSize={20}
														fill="#004990"
														radius={[
															10, 10, 10, 10
														]}
													/>
													<Line
														type="monotone"
														dataKey="Target"
														stroke="#fecb2f"
													/>
												</ComposedChart>
											</ResponsiveContainer>
										</div>
									</div>
								</div>
							</Element>
							{/* Grafica de Customer Experience NPS */}
						</div>
					</div>

					<Element name="test4" className="element planesborderTop">
						<div id={'pilares'}>
							<div></div>
						</div>
						<Parallax
							bgImage={require('../../assets/cxsite/bg-planes.png')}
							bgImageAlt="the cat"
							strength={-350}
							bgImageStyle={{ height: '1300px' }}
						>
							<div className="checklistIcons">
								<div className="wrapperMid">
									<p className="bold">
										{i18next.t('ClienteEx.net.evolu')}
									</p>
									<div className="gridopcs">
										<div className="gridOpcsCont">
											<div className="iconImg"></div>
											<p>
												{i18next.t(
													'ClienteEx.net.relia'
												)}
											</p>
											<p>
												{i18next.t(
													'ClienteEx.net.reliaSub'
												)}
											</p>
										</div>

										<div className="gridOpcsCont">
											<div className="iconImg"></div>
											<p>
												{i18next.t(
													'ClienteEx.net.exAero'
												)}
											</p>
											<p>
												{i18next.t(
													'ClienteEx.net.exAeroSub'
												)}
											</p>
										</div>

										<div className="gridOpcsCont">
											<div className="iconImg"></div>
											<p>
												{i18next.t(
													'ClienteEx.net.exAbordo'
												)}
											</p>
											<p>
												{i18next.t(
													'ClienteEx.net.exAbordoSub'
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
						</Parallax>
					</Element>

					<div id={'logrosrecientes'}>
						<div></div>
					</div>

					<div className="wavesClientes"></div>
				</div>

				{/*LOGROS*/}
				<Element name="test5" className="element">
					<div className="logrosCX">
						<div className="midLogrosCX">
							<TitleCXEvo />
							<div className="logrosCardCX">
								<LogrosCard />
							</div>
						</div>
						<div className="bluebgLogros"></div>
					</div>
				</Element>
				{/*LOGROS*/}

				<Element name="test6" className="element">
					<div id={'quejas'}>
						<div></div>
					</div>
					{/*Quejas*/}
					<div className="quejasClientes">
						{!loading &&
							entries &&
							entries.map((stateAriline) => (
								<div
									className="midWrap"
									key={stateAriline.sys.id}
								>
									<div className="topQuejas">
										<div className="textQuejasClientes">
											<h2 className="text-3xl">
												{/* {i18next.t(
													'ClienteEx.quejas.title'
												)} */}
												{entries[0].fields.blueHeaderTitle}
											</h2>
											<div className="mt-4">
												<p className="text-2xl font-bold text-white">
													{/* {i18next.t(
														'ClienteEx.quejas.trans'
													)} */}
													{entries[0].fields.blueHeaderNumber}
												</p>
												<p className="text-white font-bold text-lg capitalize">
													{/* {i18next.t("ClienteEx.quejas.title").toLowerCase()} */}
													{entries[0].fields.blueHeaderSub}
												</p>
												<p className="text-white text-base">{entries[0].fields.blueHeaderOperationDate}</p>
											</div>
											<div className="mt-5">
												<p className="text-2xl font-bold text-white">
													{/* {i18next.t(
														'ClienteEx.quejas.percentage'
													)} */}
													{entries[0].fields.blueHeaderPercentage}%
												</p>
												<p className="text-white font-bold text-lg capitalize">
													{entries[0].fields.blueHeaderSub2}
												</p>
												<p className="text-white text-base">{entries[0].fields.blueHeaderOperationDate}</p>
											</div>

											<div className="whitespace-nowrap" style={{margin: "50px 0 10px"}}>
												<p className="text-2xl font-bold text-white">
													{/* {i18next.t(
														'ClienteEx.quejas.top'
													)} */}
													{entries[0].fields.blueHeaderTitle2}
												</p>
												<p className="text-white font-bold text-lg capitalize">
													{entries[0].fields.blueHeaderDate2}
												</p>
											</div>
										</div>

										{/* <div className="imageWrapperQuejas">
											<div className="imageWrapperAbsolute"></div>
										</div> */}

										<div className="imagebgmostrador"></div>
									</div>

									<div className="bottomQuejas flex items-start justify-between" style={{flexWrap: "wrap"}}>

										<div className="flex items-center justify-start w-auto px-2 mb-4">
											<div className="w-auto flex flex-col items-center justify-center">
												<img src={EquipajeIMG} alt="Equipaje" style={{backgroundSize: "contain", height: "60px", width: "60px"}} />
												<p className="text-white font-bold text-base">
													{entries[0].fields.luggageTitle}
												</p>
											</div>
											<div className="w-auto flex flex-col items-center justify-center ml-4">
												<p className="text-white font-bold text-2xl">
													{entries[0].fields.luggaggeNumber}
												</p>
												<p className="text-white text-sm">
													Index/1KPax
												</p>
											</div>
										</div>
										
										<div className="flex items-center justify-start w-auto px-2 mb-4">
											<div className="w-auto flex flex-col items-center justify-center">
												<img src={BoletoIMG} alt="Boleto" style={{backgroundSize: "contain", height: "60px", width: "60px"}} />
												<p className="text-white font-bold text-base">
													{/* {i18next.t(
														'ClienteEx.quejas.boleto'
													)} */}
													{entries[0].fields.refundTitle}
												</p>
											</div>
											<div className="w-auto flex flex-col items-center justify-center ml-4">
												<p className="text-white font-bold text-2xl">
													{entries[0].fields.refundNumber}
												</p>
												<p className="text-white text-sm">
													Index/1KPax
												</p>
											</div>
										</div>
										
										<div className="flex items-center justify-start w-auto px-2 mb-4">
											<div className="w-auto flex flex-col items-center justify-center">
												<img src={CancelacionIMG} alt="Cancelación" style={{backgroundSize: "contain", height: "60px", width: "60px"}} />
												<p className="text-white font-bold text-base">
													{entries[0].fields.cancellationTitle}
												</p>
											</div>
											<div className="w-auto flex flex-col items-center justify-center ml-4">
												<p className="text-white font-bold text-2xl">
													{entries[0].fields.cancellationNumber}
												</p>
												<p className="text-white text-sm">
													Index/1KPax
												</p>
											</div>
										</div>
										
										<div className="flex items-center justify-start w-auto px-2 mb-4">
											<div className="w-auto flex flex-col items-center justify-center">
												<img src={DemoraIMG} alt="Demora" style={{backgroundSize: "contain", height: "60px", width: "60px"}} />
												<p className="text-white font-bold text-base">
													{entries[0].fields.delayTitle}
												</p>
											</div>
											<div className="w-auto flex flex-col items-center justify-center ml-4">
												<p className="text-white font-bold text-2xl">
													{entries[0].fields.delayNumber}
												</p>
												<p className="text-white text-sm">
													Index/1KPax
												</p>
											</div>
										</div>
										
										<div className="flex items-center justify-start w-auto px-2 mb-4">
											<div className="w-auto flex flex-col items-center justify-center">
												<img src={FallaIMG} alt="Falla en el mortal" style={{backgroundSize: "contain", height: "60px", width: "60px"}} />
												<p className="text-white font-bold text-base">
													{entries[0].fields.failureTitle}
												</p>
											</div>
											<div className="w-auto flex flex-col items-center justify-center ml-4">
												<p className="text-white font-bold text-2xl">
													{entries[0].fields.failureNumber}
												</p>
												<p className="text-white text-sm">
													Index/1KPax
												</p>
											</div>
										</div>
											{/* <div className="quejasFrecuente"> */}
												{/* <div className="quejaText">
													<p className="number">
														{
															stateAriline.fields
																.quejasEquipaje
														}
													</p>
												</div> */}
											{/* </div> */}

											{/* <div className="quejasFrecuente">
												<div className="quejaIcon"></div>
												<div className="quejaText">
													<p className="number">
														{
															stateAriline.fields
																.boletoPnr
														}
													</p>
													<p className="description">
														{i18next.t(
															'ClienteEx.quejas.boleto'
														)}
													</p>
												</div>
											</div>

											<div className="quejasFrecuente">
												<div className="quejaIcon"></div>
												<div className="quejaText">
													<p className="number">
														{
															stateAriline.fields
																.iropsQuejas
														}
													</p>
													<p className="description">
														{i18next.t(
															'ClienteEx.quejas.politica'
														)}{' '}
													</p>
												</div>
											</div> */}

											{/* <div className="quejasFrecuente">
												<div className="quejaIcon"></div>
												<div className="quejaText">
													<p className="number">
														{
															stateAriline.fields
																.paginaWebQuejas
														}
													</p>
													<p className="description">
														{i18next.t(
															'ClienteEx.quejas.pagina'
														)}
													</p>
												</div>
											</div> */}

											{/* <div className="quejasFrecuente">
												<div className="quejaIcon"></div>
												<div className="quejaText">
													<p className="number">
														{
															stateAriline.fields
																.actitudServicioQuejas
														}
													</p>
													<p className="description">
														{i18next.t(
															'ClienteEx.quejas.actitud'
														)}
													</p>

													<div id="Logros"></div>
												</div>
											</div> */}
									</div>
								</div>
							))}
					</div>
					{/*Quejas*/}
				</Element>

				<div className="backgroundImageCX"></div>
				{/* <Index/> */}
			</div>
			// </LoadingScreen>
		);
	}
}
