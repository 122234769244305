import React from "react";
import ModalLang from "react-awesome-modal";
import close from "../../assets/logo/close.svg";
import { Trans } from "react-i18next";
import i18next from "i18next"; // Asegúrate de importar i18next

const ModalLangComponent = ({
  langVisible,
  onCloseModal,
  onLangSelectedEvent,
}) => {
  return (
    <ModalLang
      visible={langVisible}
      width="auto"
      height="auto"
      effect="fadeInDown"
      onClickAway={onCloseModal}
      className="container-modal-notification"
    >
      <div className="selectLangugeModal menuModal">
        <div className="whiteModal">
          <div className="closeModal" onClick={onCloseModal}>
            <img src={close} alt="Cerrar" />
          </div>
          <div className="padding">
            <h4>
              <Trans i18nKey="Lang.selecciona"></Trans>
            </h4>
            <div className="gridFlags">
              <a className="contFlag" onClick={() => onLangSelectedEvent("es")}>
                <div className="flags spanish"></div>
                <p>{i18next.t("Lang.es")}</p>
              </a>
              <a className="contFlag" onClick={() => onLangSelectedEvent("en")}>
                <div className="flags english"></div>
                <p>{i18next.t("Lang.en")}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ModalLang>
  );
};

export default ModalLangComponent;
