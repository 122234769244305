import * as Msal from "msal";

export default class AuthService {
  constructor() {
    // Configuración de la aplicación según el entorno
    const isLocalhost = window.location.hostname === "127.0.0.1";
    const PROD_REDIRECT_URI = "https://miaeromexico.com/inicio";
    const redirectUri = isLocalhost ? window.location.origin : PROD_REDIRECT_URI;

    // Configuración de MSAL
    this.applicationConfig = {
      clientId: "21dd1c71-a432-4727-9e9d-82ef287eb99d",
      authority: "https://login.microsoftonline.com/common", // Reemplaza 'common' si usas un tenant específico
      redirectUri,
      cacheLocation: "sessionStorage", // Mejora de seguridad, evita persistencia local innecesaria
      storeAuthStateInCookie: true, // Mitiga problemas en navegadores como Safari
    };

    // Inicializar MSAL UserAgentApplication
    this.app = new Msal.UserAgentApplication({
      auth: {
        clientId: this.applicationConfig.clientId,
        authority: this.applicationConfig.authority,
        redirectUri: this.applicationConfig.redirectUri,
      },
      cache: {
        cacheLocation: this.applicationConfig.cacheLocation,
        storeAuthStateInCookie: this.applicationConfig.storeAuthStateInCookie,
      },
    });
  }

  /**
   * Inicia sesión del usuario mediante un popup.
   * @returns {Promise<Object|null>} Usuario autenticado o null si falla.
   */
  async login() {
    try {
      await this.app.loginPopup({ scopes: ["user.read"] });
      const user = this.app.getAccount();
      return user || null;
    } catch (error) {
      console.error("Error during login:", error);
      return null;
    }
  }

  /**
   * Cierra sesión del usuario y limpia datos almacenados.
   */
  logout() {
    this.app.logout();
  }

  /**
   * Adquiere un token de acceso silenciosamente o mediante popup.
   * @returns {Promise<string|null>} Token de acceso o null si falla.
   */
  async getToken() {
    try {
      const response = await this.app.acquireTokenSilent({ scopes: ["user.read"] });
      return response.accessToken;
    } catch (silentError) {
      console.warn("Silent token acquisition failed, attempting popup:", silentError);
      try {
        const response = await this.app.acquireTokenPopup({ scopes: ["user.read"] });
        return response.accessToken;
      } catch (popupError) {
        console.error("Token acquisition failed:", popupError);
        return null;
      }
    }
  }
}
