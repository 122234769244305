import React, { Component } from "react";
import { Link } from "react-router-dom";
import ArticleCards from "../../components/Home/Cards/ArticleCards";
import StateBox from "../../components/Home/StateBox/Stateairline";
import StateFlight from "../../components/Home/StateBox/flightPlaneState";
import ReportaDenuncia from "../../components/Home/StateBox/ReportaDenuncia";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import InfoEnvironment from "../../utils/info.environment";

import OktaTools from "../../components/Home/OktaTools";
import WorkTools from "../../components/Home/WorkTools/worktools";
import "./../../tailwindcss.css";
import "./../../tailwind.css";
import LoadingScreen from "react-loading-screen";
import GamReport from "../../components/Home/GamReport/gamreport";
import ModalHomeAlert from "../../components/Home/AlertHome/homeAlert";
import "./css/welcome.css";
import userLocation from "../../utils/userLocation";
import isMockUser from "../../utils/isMockUser";
import setLevelMockUser from "../../utils/setLevelMockUser";
import setMockCompany from "../../utils/setMockCompany";
import i18next from "i18next";
import { ReactComponent as Caballero } from "../../assets/identidad/caballero.svg";
import { validateMenu } from "../../utils/NewBenefitsValidation/benefits.validation";
import Swiper2 from "../../components/Slider/Swiper2.0";
import AMX_TOP from "../../components/Footer/assets/AMX_top.png";
import Index from "./../../components/Footer/index"; 
import { ReactComponent as Warning } from '../../assets/travel/important.svg';
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';
import { animateScroll as scroll} from "react-scroll";

const sha1 = require("sha1");

	// # Variables que switchean las ligas que se consumen segun sea elcaso
	// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
	// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
	// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
	/* Para productivo se ponen todas en falso */
	
	// #URl de ambiente
	const urlLocal = `${process.env.REACT_APP_LOCAL}`;
	const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
	const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
	const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`
	
	const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
	const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
	const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
	const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`
	
	// # Liga validada
	const urlValid = isLocal === true ? urlLocal 
		: isFromDev === true ? urlDev
		: isFromQA  === true? urlQA
		: urlProd
	
	// Se genera el token segun el ambiente
	const tokenValid = isLocal === true ? tokenLocal
		: isFromDev === true ? tokenDev
		: isFromQA === true ? tokenQA
		: tokenProd
	
		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});
export default class Welcome extends Component {
	constructor() {
		super();
		this.infoEnvironment = new InfoEnvironment();
		// Remplazar en el estado el email para hacer la consulta
		this.state = {
			loading: false,
			//email: 'kaalvaradoa@aeromexico.com',
			email: sessionStorage.getItem("email"),
			respuesta: "",
			data: "",
			token: "",
			language: i18next.language,
			visible: true,
			cargando: true,
			generalInfo: {
				noEmpleado: "",
				compania: "",
				nombre: "Undefined",
				apellidoMaterno: "",
				apellidoPaterno: "",
				grupo: "",
				nivel: "",
				ubicacion: "",
				posicion: "",
				direccion: "",
				direccionArea: "",
				fechaContra: "",
				antiguedadEmpleado: ""
			},
			charging: true,
			mainteinance: false,
			error: null,
			main_alert: {
				show:   false,
				message:"Te informamos que el próximo 19 de marzo, se llevarán a cabo trabajos de mantenimiento, por lo que de 12:00 h a 16:00 h el portal Mi Aeroméxico se encontrará fuera de servicio.",
				title:  "Aviso Importante:"
			}
		};
	}

	getSettings = (email) => {
		// request.get(`${endPoint}/api/settings`).then(response => {
		// 	console.log(response.body);
		// 	const settings = response.body;
		// 	this.setState({
		// 		data: settings
		// 	});
		// });
	};

	openModal() {
		this.setState({
			visible: true
		});
	}

	closeModal() {
		this.setState({
			visible: false
		});
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
		}
	}

	handleClick = (e) => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
		}
	};

	getUniqueSettings = () => {
		const email = "marci@gmail.com";
		// request
		// 	.get(
		// 		`${endPoint}/api/settings/email/${localStorage.getItem(
		// 			'email'
		// 		)}`
		// 	)
		// 	.then(response => {
		// 		console.log(response.body.settings[0]);
		// 		const data = response.body.settings[0];

		// 		this.setState({
		// 			avatar: data.avatar,
		// 			background: data.background,
		// 			alreadyRegistered: data.alreadyRegistered
		// 		});

		// 		localStorage.setItem('_id', data._id);
		// 		localStorage.setItem(
		// 			'alreadyRegistered',
		// 			data.alreadyRegistered
		// 		);
		// 		localStorage.setItem('avatar', data.avatar);
		// 		localStorage.setItem('background', data.background);
		// 	});
	};

	postSettings = () => {
		
		let lenguage = i18next.language === 'es' ? 'es' : 'en'
		fetch( `${urlValid}traduccion-${lenguage}`, { headers: cabecera })
			.then(res => res.json()) 
			.then(res => { 
				this.setState({
					main_alert: {
						show: res.data.attributes.data.MyHR.toplevel_alert.show,
						message:res.data.attributes.data.MyHR.toplevel_alert.message,
						title:  res.data.attributes.data.MyHR.toplevel_alert.title
					}
				});
			});
		// request
		// 	.post(`${endPoint}/api/settings/new`)
		// 	.set({
		// 		'Content-Type': 'application/json'
		// 	})
		// 	.send({
		// 		mail: localStorage.getItem('email'),
		// 		avatar: 'face1',
		// 		background: 'back1',
		// 		alreadyRegistered: true
		// 	})
		// 	.catch(error => console.error(error))
		// 	.then(response => {
		// 		console.log(response);
		// 	})
		// 	.then(() =>
		// 		request
		// 			.get(
		// 				`${endPoint}/api/settings/email/${localStorage.getItem(
		// 					'email'
		// 				)}`
		// 			)
		// 			.then(response2 => {
		// 				console.log(response2.body.settings[0]);
		// 				const data = response2.body.settings[0];
		// 				this.setState({
		// 					avatar: data.avatar,
		// 					background: data.background,
		// 					alreadyRegistered: data.alreadyRegistered
		// 				});
		// 				localStorage.setItem('_id', data._id);
		// 				localStorage.setItem(
		// 					'alreadyRegistered',
		// 					data.alreadyRegistered
		// 				);
		// 				localStorage.setItem('avatar', data.avatar);
		// 				localStorage.setItem('background', data.background);
		// 			})
		// 	);
	};

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		this.postSettings();
		isMockUser() ? this.getMockInfo() : this.getInfo();
		document.addEventListener("mousedown", this.handleClick, false);
		scroll.scrollToTop();

	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	isL10() {
		return localStorage.getItem("email") === "amaccesoinvitado@aeromexico.com";
	}

	getMockInfo = async () => {
		localStorage.setItem("userma", "Userma");
		localStorage.setItem("nombre", "Usuario");
		localStorage.setItem("pais", "MX");
		localStorage.setItem("grupo", "XXXX");
		localStorage.setItem("noEmpleado", "-");
		setMockCompany();
		localStorage.setItem("apellidoMaterno", "-");
		localStorage.setItem("apellidoPaterno", "-");
		setLevelMockUser();
		localStorage.setItem("ubicacion", "-");
		localStorage.setItem("posicion", "-");
		localStorage.setItem("direccion", "-");
		localStorage.setItem("direccionArea", "-");
		localStorage.setItem("fechaContra", "-");
		localStorage.setItem("antiguedadEmpleado", "-");

		
		this.setState({
			error: false,
			charging: false,
			generalInfo: {
				nombre: localStorage.getItem("nombre"),
				grupo: localStorage.getItem("grupo")
			}
		});

		this.setUserLocation();
	};

	setUserLocation() {
		var location = userLocation(
			localStorage.getItem("email"),
			localStorage.getItem("ubicacion"),
			localStorage.getItem("compania")
		);
		
		localStorage.setItem("location", location);
	}

	stringToHex(str){
		let hex = '';
		for(let i=0; i<str.length;i++){
			hex += str.charCodeAt(i).toString(16).toUpperCase();
		}
		return hex;
	}

	getInfo = async () => {

		// const stringAHexadecimal = (str) =>{
		// 	let hex = '';
		// 	for(let i = 0; i< str.length; i++){
		// 		hex += str.charCodeAt(i).toString(16).padStart(2,'0');
		// 	}
		// 	return hex;
		// }

		

		const HOST = "NOMBREHOST";
		const token = sha1(`${HOST}|${localStorage.getItem("email")}`).toUpperCase();
		// console.log("Token_PruebaServicio: ", token);
		//const email = new Buffer(localStorage.getItem("email")).toString("hex").toUpperCase();
		const email = this.stringToHex(localStorage.getItem("email")).toUpperCase(); 

		// console.log("email_PruebasServicio: ",email);

		const URL = this.infoEnvironment.api_url({
			host: window.location.hostname
		});

		let formData = new FormData();

		formData.append("token", token);
		formData.append("email", email);
		formData.append("tipo", "1");
		formData.append("tipo_salida", "JSON");

		fetch(URL, {
			body: formData,
			method: "post"
		})
			.then((data) => data.json())
			.then((data) => {
				localStorage.setItem("userma", data);
				localStorage.setItem("nombre", data.nombre);
				localStorage.setItem("grupo", data.grupo);
				localStorage.setItem("pais", data.country);
				localStorage.setItem("noEmpleado", data.noEmpleado);
				localStorage.setItem("compania", data.compania);
				localStorage.setItem("apellidoMaterno", data.apellidoMaterno);
				localStorage.setItem("apellidoPaterno", data.apellidoPaterno);
				localStorage.setItem("nivel", data.nivel);
				localStorage.setItem("ubicacion", data.ubicacion);
				localStorage.setItem("posicion", data.posicion);
				localStorage.setItem("direccion", data.direccion);
				localStorage.setItem("direccionArea", data.direccionArea);
				localStorage.setItem("fechaContra", data.fechaContra);
				localStorage.setItem("antiguedadEmpleado", data.antiguedadEmpleado);
				localStorage.setItem("codigo_empresa_AM", data.codigo_empresa);
				localStorage.setItem("tipo_empleado_AM", data.tipo_empleado);
				localStorage.setItem("no_empleado_AM", data.noEmpleado);
				this.setState({
					error: false,
					charging: false,
					generalInfo: {
						nombre: localStorage.getItem("nombre"),
						grupo: localStorage.getItem("grupo")
					}
				});
			})
			.catch((error) => {
				this.setState({
					error: error
				});
			});
		console.log("Correo devuelto de welcome",email);
		this.setUserLocation();
	};




	render() {
		const { loading, charging } = this.state;
		if (this.state.mainteinance) {
			return (
				<div
					style={{
						background: "white",
						height: "100vh",
						width: "100vw",
						position: "fixed",
						left: 0,
						top: 0,
						zIndex: 1000
					}}>
					<div className="container" style={{ height: "100vh" }}>
						<div className="row align-items-center" style={{ height: "100vh" }}>
							<div className="col-12">
								<h1>{this.state.language === 'es' ? "El sitio está actualmente en mantenimiento":"The site is currently under maintenance"}</h1>
								<p>
									{ this.state.language === 'es' ? "El día de mañana regresará a la normalidad. Este es un proceso controlado y 									realizado por las áreas de Comunicación Interna y TI por lo que no es necesario reportar este mensaje"
									:"Tomorrow it will return to normal. This is a controlled process and carried out by the Internal Communication and IT areas so it is not necessary report this message" }
								</p>
								<Caballero style={{ height: "100px", width: "auto" }} />
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<LoadingScreen
					loading={loading}
					bgColor="#f5f5f5"
					spinnerColor="#007dc3"
					textColor="#0b2343" 
					text={i18next.t("News.cargando")}>
					
					{/* MAIN ALERT TOP LEVEL */}
				{this.state.main_alert.show && (
					<div
						className="fixed left-0 w-full bg-amYellow h-16 z-40 top-level-alert flex items-center justify-center"
						style={{ top: '60px' }}
					>
						<Warning className="h-6 w-6 text-amBlack fill-current mr-2" />
						<p className="text-amBlack text-left">
							<strong>{this.state.main_alert.title}:</strong>{' '}
							{this.state.main_alert.message}
						</p>
					</div>
				)}
				{/* : ""} */}
					{/* <Arriba/> */}
					<GamReport />

					<ModalHomeAlert />

					{/* <div className="swiperFirst">{!loading && <Slider />}</div> */}
					<div className="swiperFirst">{!loading && <Swiper2 />}</div>

					<div className="WhiteBackgrounds">
						<div className="BackMiddleWrap">
							<div className="WrapperCards">
								<div className="top-employer-holder mb-2 mt-4 flex w-full items-center justify-start">
									<img src={AMX_TOP} alt="Top Employer Mexico 2023" className="h-16 w-auto" />
									<p className="ml-4 pr-4 text-xl font-semibold">
										{this.state.language === 'es'? "Hemos sido reconocidos, por segundo año consecutivo, con la certificación Top Employer en México."
										:"We have been recognized, for the second consecutive year, with the Top Employer certification in Mexico."}
									</p>
								</div> 
								
								<ArticleCards />
								
								<p className="accessText">{i18next.t("Inicio.accesos.title")}</p>
								<ol className="buttonsLinks">
									<li>
										<a
											href={
												this.state.language === "es"
													? `https://compliance.aeromexico.com/doc/CODIGO_DE_CONDUCTA_ESP.pdf`
													: `https://compliance.aeromexico.com/doc/CODIGO_DE_CONDUCTA_ENG.pdf`
													// ? `${process.env.REACT_APP_SRC_URL}2024/codigo_conducta/codigo_de_conducta24.pdf`
													// : `${process.env.REACT_APP_SRC_URL}2024/codigo_conducta/codigo_de_conducta24.pdf`
											}
											target="_blank"
											className="flex h-full flex-col items-center justify-center bg-transparent p-4 pb-7 text-[#020c41] hover:text-[#fa0073]"
											rel="noopener noreferrer">
											<div className="IconLinks flex w-full items-center justify-center">
												{/* <img src={imageaccess01} alt="icon link" /> */}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="h-16 w-16 stroke-current"
													viewBox="0 0 20 12">
													<g
														fill="none"
														fillRule="evenodd"
														// stroke="#020C41"
														strokeLinecap="round"
														strokeLinejoin="round"
														transform="translate(.655 .891)">
														<line
															x1="12.855"
															x2="11.105"
															y1="7.519"
															y2="6.039"
															strokeWidth=".6"
														/>
														<line
															x1="11.245"
															x2="9.815"
															y1="8.409"
															y2="7.239"
															strokeWidth=".6"
														/>
														<path
															strokeWidth=".6"
															d="M3.444502 5.318962C3.954502 6.778962 8.044502 9.938962 8.044502 9.938962 9.034502 10.778962 9.924502 9.498962 9.114502 8.808962L8.494502 8.208962M8.494502 8.208962 10.184502 9.678962C11.154502 10.498962 12.104502 9.158962 11.304502 8.478962L11.734502 8.838962C12.584502 9.558962 13.704502 8.228962 12.854502 7.508962L12.924502 7.568962C13.774502 8.288962 14.894502 6.958962 14.044502 6.238962L9.854502 2.818962C9.854502 2.818962 9.044502 3.128962 8.734502 3.038962 8.734502 3.038962 7.524502 4.888962 6.644502 4.248962 5.764502 3.608962 6.644502.868962 9.124502.478962 9.124502.478962 10.244502.158962 12.974502 1.568962L13.984502 1.258962"
														/>
														<rect
															width="6.39"
															height="3.09"
															x="-.838"
															y="1.95"
															strokeWidth=".6"
															transform="rotate(-74.188 2.357 3.495)"
														/>
														<rect
															width="3.09"
															height="6.39"
															x="14.786"
															y=".469"
															strokeWidth=".6"
															transform="rotate(-15.812 16.331 3.664)"
														/>
														<path
															strokeWidth=".6"
															d="M4.594502 1.258962C4.594502 1.258962 4.734502.998962 7.884502.928962M14.314502 6.678962C14.314502 6.678962 15.094502 6.878962 15.504502 6.408962"
														/>
													</g>
												</svg>
											</div>
											<div className="TextLinks">
												<div className="TextLinksCell">
													<p className="TitleLink">{i18next.t("Inicio.accesos.conducta")}</p>
													{/*<p className="CopyLink">Descarga el último manual</p>*/}
												</div>
											</div>
										</a>
									</li>
									<li>
										<a href="https://www.aeromexico.com/es-mx/inversionistas/reportes-financieros" target="_blank"
											className="flex h-full flex-col items-center justify-center bg-transparent p-4 pb-7 text-[#020c41] hover:text-[#fa0073]">
											<div className="IconLinks flex w-full items-center justify-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="mb-2 h-12 w-12 stroke-current"
													viewBox="0 0 18 18">
													<g
														fill="none"
														fillRule="evenodd"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth=".6"
														transform="translate(.53 .51)">
														<path d="M5.32 9C5.32 9.52 4.9 9.94 4.38 9.94 3.86 9.94 3.44 9.52 3.44 9 3.44 8.48 3.86 8.06 4.38 8.06 4.89 8.05 5.32 8.48 5.32 9ZM9.45 12.46C9.45 12.98 9.03 13.4 8.51 13.4 7.99 13.4 7.57 12.98 7.57 12.46 7.57 11.94 7.99 11.52 8.51 11.52 9.03 11.52 9.45 11.94 9.45 12.46ZM13.58 5.53C13.58 6.05 13.16 6.47 12.64 6.47 12.12 6.47 11.7 6.05 11.7 5.53 11.7 5.01 12.12 4.59 12.64 4.59 13.16 4.58 13.58 5.01 13.58 5.53Z" />
														<line x1="13.36" x2="16.94" y1="6.13" y2="9.12" />
														<line x1="8.99" x2="12.15" y1="11.65" y2="6.34" />
														<line x1="5.09" x2="7.78" y1="9.6" y2="11.86" />
														<line x2="3.65" y1="12.58" y2="9.6" />
														<path d="M14.44,16.94 L2.5,16.94 C1.12,16.94 0,15.82 0,14.44 L0,2.5 C0,1.12 1.12,0 2.5,0 L14.44,0 C15.82,0 16.94,1.12 16.94,2.5 L16.94,14.44 C16.94,15.82 15.82,16.94 14.44,16.94 Z" />
													</g>
												</svg>
											</div>
											<div className="TextLinks">
												<div className="TextLinksCell">
													<p className="TitleLink">
														{i18next.t("Inicio.accesos.trimestral")}
													</p>
													{/*<p className="CopyLink">Descarga el Reporte</p>*/}
												</div>
											</div>
										</a>
									</li>
									<li>
										<a href="https://www.aeromexico.com/es-mx/inversionistas/reportes-financieros" target="_blank"
											className="flex h-full flex-col items-center justify-center bg-transparent p-4 pb-7 text-[#020c41] hover:text-[#fa0073]">
											<div className="IconLinks flex w-full items-center justify-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="mb-2 h-12 w-12 stroke-current"
													viewBox="0 0 16 20">
													<g
														fill="none"
														fillRule="evenodd"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth=".6"
														transform="translate(.59 1.01)">
														<path d="M3.65,16 L3.65,16 C3.65,16.32 3.91,16.57 4.24,16.57 C4.56,16.57 4.83,16.31 4.83,16 L4.83,16 C4.83,15.68 4.57,15.43 4.24,15.43 C3.91,15.43 3.65,15.68 3.65,16 Z" />
														<path d="M8.42 3.55C8.42 2.12 7.68.86 6.55.12 6.55.52 6.55 1.79 6.55 2.81L5.42 4.49 3 4.49 1.87 2.81C1.87.19 1.87 2.54 1.87.12.74.86 0 2.12 0 3.55 0 5.01 1.39 6.3 2.57 7.03L1.96 15.74C1.96 16.96 2.97 17.95 4.22 17.95 5.47 17.95 6.48 16.96 6.48 15.74L5.85 7.03C7.02 6.3 8.42 5.01 8.42 3.55ZM14.13 10.67C14.13 10.26 14.41 9.91 14.83 9.71L14.83 8.09 10.06 8.09 10.06 9.72C10.48 9.91 10.76 10.27 10.76 10.68 10.76 11.09 10.48 11.44 10.06 11.64L10.06 16.08C10.06 17.13 11.13 17.98 12.44 17.98 13.76 17.98 14.82 17.13 14.82 16.08L14.82 11.64C14.41 11.44 14.13 11.08 14.13 10.67Z" />
														<line x1="12.44" x2="12.44" y1="8.09" y2="2.03" />
														<polygon points="12.92 0 11.97 0 11.6 .87 11.97 2.03 12.92 2.03 13.28 .87" />
														<line x1="12.44" x2="12.44" y1="12.32" y2="16" />
													</g>
												</svg>
											</div>
											<div className="TextLinks">
												<div className="TextLinksCell">
													<p className="TitleLink">
														{i18next.t("Inicio.accesos.operacional")}
													</p>
													{/*<p className="CopyLink">Descarga el Reporte</p>*/}
												</div>
											</div>
										</a>
									</li>
									<li>
										<Link
											to="/nosotros/boletin-industria"
											className="flex h-full flex-col items-center justify-center bg-transparent p-4 pb-7 text-[#020c41] hover:text-[#fa0073]">
											<div className="IconLinks flex w-full items-center justify-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="mb-2 h-12 w-12 stroke-current"
													viewBox="0 0 20 20">
													<g
														fill="none"
														fillRule="evenodd"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth=".6"
														transform="translate(.66 1.21)">
														<line x2="18.68" y1="17.58" y2="17.58" />
														<polygon points="8.37 17.58 2.51 17.58 2.51 3.53 8.37 0" />
														<rect width="6.51" height="13.44" x="9.67" y="4.14" />
														<line x1="12.92" x2="12.92" y1="1.64" y2="4.14" />
														<line x1="9.67" x2="16.18" y1="15.14" y2="15.14" />
														<line x1="11.62" x2="11.62" y1="7.2" y2="15.14" />
														<line x1="12.92" x2="12.92" y1="7.2" y2="15.14" />
														<line x1="14.23" x2="14.23" y1="7.2" y2="15.14" />
														<line x1="4.49" x2="6.41" y1="14.53" y2="14.53" />
														<line x1="4.49" x2="6.41" y1="12.7" y2="12.7" />
														<line x1="4.49" x2="6.41" y1="10.86" y2="10.86" />
														<line x1="4.49" x2="6.41" y1="9.03" y2="9.03" />
														<line x1="4.49" x2="6.41" y1="7.2" y2="7.2" />
														<line x1="4.49" x2="6.41" y1="5.36" y2="5.36" />
													</g>
												</svg>
											</div>
											<div className="TextLinks">
												<div className="TextLinksCell">
													<p className="TitleLink">{i18next.t("Inicio.accesos.industria")}</p>
													{/*<p className="CopyLink">Boletin de Industria 2018</p>*/}
												</div>
											</div>
										</Link>
									</li>
									<li>
										<Link
											to="/nosotros/responsabilidad-social#reportes-sostenibilidad"
											className="flex h-full flex-col items-center justify-center bg-transparent p-4 pb-7 text-[#020c41] hover:text-[#fa0073]">
											<div className="IconLinks flex w-full items-center justify-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="mb-2 h-12 w-12 stroke-current"
													viewBox="0 0 18 18">
													<g
														fill="none"
														fillRule="evenodd"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth=".6"
														transform="translate(.5 .5)">
														<circle cx="8.5" cy="8.5" r="8.5" />
														<path d="M3 3.6C3 3.6 1.9 6.2 3.2 5.7 4.2 5.3 4.3 4.9 4.3 4.9L4.9 3.9C4.9 3.9 5 5.4 5.1 5.6 5.2 5.8 5.7 6.4 5.9 5.6 6.2 4.1 6.3 3.6 6.8 3.6 7.3 3.6 8.5 3.2 8.6 4.5 8.7 5.9 8.5 5.1 7.7 5.4 7.1 5.8 6.9 7 7.6 7.2 8 7.3 8.8 7.6 9.3 7.8 11 8.4 11 10.3 11 11.9 11 11.9 11 13.8 11.7 13.3 12.4 12.9 14.3 9.9 13.9 8.8 13.5 7.7 13.5 7.6 14.2 7.2 14.9 6.8 15.4 6.3 15.4 5.8M1.6 10.6C1.6 10.6 1.8 8.2 2.3 8.6 2.8 9 3 9 3.4 9.4 3.8 9.8 5.6 10.1 5 10.6 4.4 11.1 4.2 11.1 3.4 13.5" />
													</g>
												</svg>
											</div>
											<div className="TextLinks">
												<div className="TextLinksCell">
													<p className="TitleLink">{i18next.t("Inicio.accesos.sosten")}</p>
													{/*<p className="CopyLink">Boletin de Industria 2018</p>*/}
												</div>
											</div>
										</Link>
									</li>
								</ol>

								<OktaTools />

								{!loading && <WorkTools />}
							</div>
							<div className="WrapperContent">
								{(localStorage.getItem("nivel") === "1" &&
									localStorage.getItem("grupo") !== "99 NSD - NO SINDICALIZADOS") ||
								localStorage.getItem("nivel") === "2" ||
								localStorage.getItem("nivel") === "3" ||
								localStorage.getItem("nivel") === "4" ||
								localStorage.getItem("nivel") === "5" ||
								localStorage.getItem("nivel") === "6" ||
								localStorage.getItem("nivel") === "7" ||
								localStorage.getItem("nivel") === "8" ||
								localStorage.getItem("nivel") === "9" ||
								localStorage.getItem("nivel") === "10" ||
								localStorage.getItem("nivel") === "11" ||
								localStorage.getItem("nivel") === "12" ||
								(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
									localStorage.getItem("email") === "nferri@aeromexico.com") ||
								(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
									localStorage.getItem("email") === "jsarvis@aeromexico.com") ? (
									<Link to={"/recompensa-total/perfil/mi-compensaciones"} className="ProfileWrapBox">
										<div className="PaddingProfileBox">
											<div className="GridProfileBox">
												<div
													className={
														localStorage.getItem("alreadyRegistered") === "true"
															? `UserProfilePic ${localStorage.getItem("avatar")}`
															: `UserProfilePic`
													}
												/>
												<div className="TextProfileWrap">
													<p className="WelcomeText text-[#020C41]">
														{i18next.t("Profile.bienvenido")}
														, <br />
														{/* {!charging &&
															this.state.generalInfo.nombre &&
															this.state.generalInfo.nombre.toLowerCase() + "!"} */}
														{!charging &&
															localStorage.getItem("nombre") &&
															localStorage.getItem("nombre").toLowerCase() + "!"}
													</p>

													{this.state.charging === true ? (
														<ReactPlaceholder
															showLoadingAnimation
															ready={this.state.ready}></ReactPlaceholder>
													) : (
														""
													)}
													

													{/* {(this.state.generalInfo.nombre.split(" ")[0])[0].toUpperCase() + (this.state.generalInfo.nombre.split(" ")[0]).toLowerCase().slice(1)}! */}

													{/*{(this.state.generalInfo.nombre.toLowerCase())}!
							
							<p className="BenefitsText">Ver beneficios y más</p>*/}
													{validateMenu() && (
														<Link
															to="/recompensa-total/perfil/mi-compensaciones"
															className="bg-[rgba(3, 92, 247, 0.15)] text-[#035cf7]">
															{i18next.t("Profile.miexper")}
														</Link>
													)}
													{/* {
														(
														<Link
															to="/recompensa-total/perfil/mi-compensaciones"
															className="bg-[rgba(3, 92, 247, 0.15)] text-[#035cf7]">
															{i18next.t("Profile.miexper")}
														</Link>
													)
													} */}
												</div>
											</div>
										</div>
									</Link>
								) : null}

								<div className="fixedStates">
									<StateBox />
									<StateFlight />
									<ReportaDenuncia />
								</div>
							</div>
						</div>
					</div>

						
				</LoadingScreen>
			);
		}
	}
}
