export const UTILS_COMPANIES_GLOSARY = [
	{
		code: '02',
		fullName: 'Aeroméxico',
		number: 2,
		title: 'AMX'
	},
	{
		code: '03',
		fullName: 'AMC',
		number: 3,
		title: 'AMConnect'
	},
	{
		code: '04',
		fullName: 'PLM',
		number: 4,
		title: 'PLM'
	},
	{
		code: '05',
		fullName: 'PLM',
		number: 5,
		title: 'PLM'
	},
	{
		code: '08',
		fullName: 'Cargo',
		number: 8,
		title: 'AMCargo'
	},
	{
		code: '09',
		fullName: 'Sistem',
		number: 9,
		title: 'Sistem'
	},
	{
		code: '13',
		fullName: 'AFI',
		number: 13,
		title: 'AFI'
	},
	{
		code: '37',
		fullName: 'CSC',
		number: 37,
		title: 'AMCsc'
	},
	{
        code: '99',
        fullName: 'Internacional Aerovías de México',
        number: 99,
        title: 'AMInternacional'
    },
	{
		specialCode: [
			'08 NSM - NO SINDICALIZADOS MINIMO',
			'37 NSM - NO SINDICALIZADOS MINIMO'
		],
		fullName: 'NSM No Sindicalizados Minimo',
		title: 'NSM No Sindicalizados Minimo'
	}
];

// AMFormacion
// AMServicios
