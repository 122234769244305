import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import "./css/mobile-menu.css";
import i18next from "i18next";
import Modal from "react-awesome-modal";
import initLang from "../../utils/initLang";
import $ from "jquery";
import close from "../../assets/logo/close.svg";
import isMockUserVentaEspecial from "../../utils/isMockUserVentaEspecial";
import isTravelBenefitsActive from "../../utils/isTravelBenefitsActive";
import core from "../../assets/images/menu-core.svg";
import menuWork from "../../assets/images/menu-work.svg";
import logout from "../../assets/images/menu-logout.svg";
import notify from "../../assets/images/menu-notify.svg";
import notifyApp from "../../assets/images/menu-notify-app.svg";
import Notifications from "../Header/Notification/Notifications";
import { validateMenu } from "../../utils/NewBenefitsValidation/benefits.validation";
import { BrowserRouter as Router } from 'react-router-dom';
var noScroll = require("no-scroll");

// Utility function
function Util() { }

/* 
  class manipulation functions
*/
Util.hasClass = function (el, className) {
  if (el.classList) return el.classList.contains(className);
  else
    return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
};

Util.addClass = function (el, className) {
  var classList = className.split(" ");
  if (el.classList) el.classList.add(classList[0]);
  else if (!Util.hasClass(el, classList[0])) el.className += " " + classList[0];
  if (classList.length > 1) Util.addClass(el, classList.slice(1).join(" "));
};

Util.removeClass = function (el, className) {
  var classList = className.split(" ");
  if (el.classList) el.classList.remove(classList[0]);
  else if (Util.hasClass(el, classList[0])) {
    var reg = new RegExp("(\\s|^)" + classList[0] + "(\\s|$)");
    el.className = el.className.replace(reg, " ");
  }
  if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(" "));
};

Util.toggleClass = function (el, className, bool) {
  if (bool) Util.addClass(el, className);
  else Util.removeClass(el, className);
};

Util.setAttributes = function (el, attrs) {
  for (var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
};

/* 
  DOM manipulation
*/
Util.getChildrenByClassName = function (el, className) {
  var children = el.children,
    childrenByClass = [];
  for (var i = 0; i < el.children.length; i++) {
    if (Util.hasClass(el.children[i], className))
      childrenByClass.push(el.children[i]);
  }
  return childrenByClass;
};

/* 
  Animate height of an element
*/
Util.setHeight = function (start, to, element, duration, cb) {
  var change = to - start,
    currentTime = null;

  var animateHeight = function (timestamp) {
    if (!currentTime) currentTime = timestamp;
    var progress = timestamp - currentTime;
    var val = parseInt((progress / duration) * change + start);
    element.setAttribute("style", "height:" + val + "px;");
    if (progress < duration) {
      window.requestAnimationFrame(animateHeight);
    } else {
      cb();
    }
  };

  //set the height of the element before starting animation -> fix bug on Safari
  element.setAttribute("style", "height:" + start + "px;");
  window.requestAnimationFrame(animateHeight);
};

/* 
  Smooth Scroll
*/

Util.scrollTo = function (final, duration, cb) {
  var start = window.scrollY || document.documentElement.scrollTop,
    currentTime = null;

  var animateScroll = function (timestamp) {
    if (!currentTime) currentTime = timestamp;
    var progress = timestamp - currentTime;
    if (progress > duration) progress = duration;
    var val = Math.easeInOutQuad(progress, start, final - start, duration);
    window.scrollTo(0, val);
    if (progress < duration) {
      window.requestAnimationFrame(animateScroll);
    } else {
      cb && cb();
    }
  };

  window.requestAnimationFrame(animateScroll);
};

/* 
  Focus utility classes
*/

//Move focus to an element
Util.moveFocus = function (element) {
  if (!element) element = document.getElementsByTagName("body")[0];
  element.focus();
  if (document.activeElement !== element) {
    element.setAttribute("tabindex", "-1");
    element.focus();
  }
};

/* 
  Misc
*/

Util.getIndexInArray = function (array, el) {
  return Array.prototype.indexOf.call(array, el);
};

Util.cssSupports = function (property, value) {
  if ("CSS" in window) {
    return CSS.supports(property, value);
  } else {
    var jsProperty = property.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    return jsProperty in document.body.style;
  }
};

/* 
  Polyfills
*/
//Closest() method
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

//Custom Event() constructor
if (typeof window.CustomEvent !== "function") {
  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
}

/* 
  Animation curves
*/
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

(function () {
  // Multi-Level Accordion Menu - by CodyHouse.co
  var accordionsMenu = document.getElementsByClassName(
    "cd-accordion--animated"
  );

  if (accordionsMenu.length > 0 && window.requestAnimationFrame) {
    for (var i = 0; i < accordionsMenu.length; i++) {
      (function (i) {
        accordionsMenu[i].addEventListener("change", function (event) {
          animateAccordion(event.target);
        });
      })(i);
    }

    function animateAccordion(input) {
      var bool = input.checked,
        dropdown =
          input.parentNode.getElementsByClassName("cd-accordion__sub")[0];

      Util.addClass(dropdown, "cd-accordion__sub--is-visible"); // make sure subnav is visible while animating height

      var initHeight = !bool ? dropdown.offsetHeight : 0,
        finalHeight = !bool ? 0 : dropdown.offsetHeight;

      Util.setHeight(initHeight, finalHeight, dropdown, 200, function () {
        Util.removeClass(dropdown, "cd-accordion__sub--is-visible");
        dropdown.removeAttribute("style");
      });
    }
  }
})();

export default class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charging: true,
      visible: false,
      visible2: false,
      lang: "",
      langVisible: false,
      langClass: "non",
      ventaEspecial: false,
    };
  }

  onLangSelectedEvent = (l) => {
    if (this.state.lang === l) {
      this.setState({
        langVisible: false,
        langClass: "non",
      });
      return;
    }
    i18next.changeLanguage(l);
    this.setState({
      lang: l,
    });
    window.location.reload();
  };

  planVueloLink() {
    if (i18next.language === "en") {
      return `${process.env.REACT_APP_SRC_URL}plan-vuelo/FLIGHT%20PLAN%20ENG%202022.pdf`;
      //return "https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/plan-vuelo/FLIGHT%20PLAN%20ENG%202022.pdf";
    } else {
      return `${process.env.REACT_APP_SRC_URL}plan-vuelo/FLIGHT%20PLAN%20ESP%202022.pdf`;
      //return "https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/plan-vuelo/FLIGHT%20PLAN%20ESP%202022.pdf";
    }
  }

  defendamosNuestroLink() {
    if (i18next.language === "en") {
      return "/lets-defend-our-sky";
    } else {
      return "/defendamos-nuestro-cielo";
    }
  }

  async fetchMenuItems() {
    const hasVenta = await fetch(
      encodeURI(`${process.env.REACT_APP_CADMIN}/ve-usuarios?filters[email][$contains]=${localStorage.getItem(
        "email"
      )}`)
    )
      .then((res) => res.json())
      .catch(console.error);
    await fetch(`${process.env.REACT_APP_CADMIN}/menu-items-validator`)
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          ...this.state,
          ventaEspecial: hasVenta.data.length > 0 ? response.venta_especial : false,
          // servicios: response.servicios
        });
      })
      .catch(console.error);
  }

  openModal() {
    this.setState({
      visible: true,
    });
    noScroll.on();
  }

  closeModal() {
    this.setState({
      visible: false,
    });
    noScroll.off();
  }

  openModal2() {
    this.setState({
      visible2: true,
    });
    noScroll.on();
  }

  closeModal2() {
    this.setState({
      visible2: false,
    });
    noScroll.off();
  }

  openModalLang() {
    var vis = !this.state.langVisible;
    this.setState({
      langVisible: vis,
      langClass: vis ? "activeLang" : "non",
    });
  }

  closeModalLang() {
    this.setState({
      langVisible: false,
      langClass: "non",
    });
  }
  componentDidMount() {
    initLang(this);

  }

  render() {
    const { charging } = this.state;

    return (
      <div>
        <Modal
          visible={this.state.visible}
          width="100%"
          height="auto"
          effect="fadeInDown"
          onClickAway={() => this.closeModal()}
        >
          <div ref={this.setWrapperRef} className="modal-notification">
            <div className="scrollModalAlert">
              <div className="modalPadding">
                <div
                  class="closeModal"
                  href="javascript:void(0);"
                  onClick={() => this.closeModal()}
                >
                  <img src={close} alt="cerrar"></img>
                </div>
                <h1>Contactos GAM</h1>
                <p>
                  Encuentra los números de contacto de las distintas áreas de
                  asistencia de Grupo Aeroméxico.
                </p>
                <div className="tableGamContact">
                  <ol>
                    <li>
                      <div className="leftTable">
                        <p>Servicios Internos</p>
                      </div>
                      <div className="leftTablePhone">
                        <a href="+525591324535">(55) 9132 4535</a>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>Seguridad</p>
                      </div>
                      <div className="leftTablePhone">
                        <a href="+525591324697">(55) 9132 4697</a> -{" "}
                        <a href="+525591324852">(55) 9132 4852</a>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>Lobby Torre Mapfre</p>
                      </div>
                      <div className="leftTablePhone">
                        <a href="tel:+525591324647">(55) 9132 4647</a>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>Mi línea de atención</p>
                      </div>
                      <div className="leftTablePhone">
                        <a href="tel:+525591324488">(55) 9132 4488</a>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>Call center</p>
                      </div>
                      <div className="leftTablePhone">
                        <a href="tel:+525551334000">(55) 5133 4000</a> -{" "}
                        <a href="tel:+525591324488">(55) 9132 4488</a>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>Caja de nómina y pagaduría</p>
                      </div>
                      <div className="leftTablePhone">
                        <a href="+525556565659">(55) 5656 5659</a> -{" "}
                        <a href="tel:+525556575660">(55) 5657 5660</a>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          visible={this.state.visible2}
          width="100%"
          height="auto"
          effect="fadeInDown"
          onClickAway={() => this.closeModal2()}
        >
          <div ref={this.setWrapperRef} className="modal-notification">
            <div className="scrollModalAlert">
              <div className="modalPadding">
                <div
                  class="closeModal"
                  href="javascript:void(0);"
                  onClick={() => this.closeModal2()}
                >
                  <img src={close} alt="cerrar"></img>
                </div>
                <h1>{i18next.t("MainMenu.nosotros.otros.localidadescdmx")}</h1>
                <p>{i18next.t("MainMenu.nosotros.otros.direcciones")}</p>
                <div className="tableGamContact">
                  <ol className="lugaresDirecciones">
                    <li>
                      <div className="leftTable">
                        <p>Reforma</p>
                      </div>
                      <div className="leftTablePhone">
                        <p className="small">
                          Torre Mapfre, Avenida Paseo de la Reforma
                        </p>
                        <p className="small smallWhite">
                          No. 243 Col. Renacimiento, Del. Cuauhtémoc, C.P.
                          06500, Ciudad de México
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>CECAM | Terminal de servicios</p>
                      </div>
                      <div className="leftTablePhone">
                        <p className="small">Av. Tahel</p>
                        <p className="small smallWhite">
                          S/N Col. Pensador Mexicano Del. Venustiano Carranza
                          C.P. 15510, Ciudad de México
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>Hangar Oriente</p>
                      </div>
                      <div className="leftTablePhone">
                        <p className="small">Av. Rio Churubusco</p>
                        <p className="small smallWhite">
                          No. 16 Aeropuerto Internacional Benito Juárez, C.P.
                          15640, Ciudad de México
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>Hangar Connect</p>
                      </div>
                      <div className="leftTablePhone">
                        <p className="small">
                          Eje1 Norte. Av. Hangares de Aviación Fuerza Aérea
                          Mexicana
                        </p>
                        <p className="small smallWhite">
                          No. 380 C.P. 15700 Ciudad de México
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>Aeroméxico Cargo</p>
                      </div>
                      <div className="leftTablePhone">
                        <p className="small">
                          Comodoro P.A. Carlos Castillo Bretón
                        </p>
                        <p className="small smallWhite">
                          S/N Terminal de carga Zona D, Hangares Terminal 2
                          AICM, C.P. 15520, Ciudad de México
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="leftTable">
                        <p>AICM T2</p>
                      </div>
                      <div className="leftTablePhone">
                        <p className="small">
                          Aeropuerto Internacional de la Ciudad de México
                        </p>
                        <p className="small smallWhite">
                          Av. Capt. Carlos León S/N Terminal 2 - Bahía L2, Zona
                          Federal C.P. 15620 México, D.F.
                        </p>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="MobileMenuWrapper">
          <div className="header">
            <div className="ExtraMenu">
              <div className="ProfileUser">
                <div
                  className={
                    localStorage.getItem("alreadyRegistered") === "true"
                      ? `ImageUser ${localStorage.getItem("avatar")}`
                      : `ImageUser`
                  }
                />
                <div className="MenuUserProfile">
                  <div className="BreakWrap noiconsListMenu">
                    {validateMenu() ? (
                      <Router>
                        <Link to="/recompensa-total/perfil/mi-compensaciones">
                          <img className="ImageMenu" src={core} />
                          <span>{i18next.t("Profile.recompensa")}</span>
                        </Link>
                      </Router>
                    ) : (
                      ""
                    )}

                    <a
                      href="http://autoservicio.aeromexico.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="ImageMenu" src={core} alt="Menu" />
                      <span>{i18next.t("Profile.core")}</span>
                    </a>

                    {(localStorage.getItem("nivel") === "1" &&
                      localStorage.getItem("grupo") !=
                      "99 NSD - NO SINDICALIZADOS") ||
                      localStorage.getItem("nivel") === "2" ||
                      localStorage.getItem("nivel") === "3" ||
                      localStorage.getItem("nivel") === "4" ||
                      localStorage.getItem("nivel") === "5" ||
                      localStorage.getItem("nivel") === "6" ||
                      localStorage.getItem("nivel") === "7" ||
                      localStorage.getItem("nivel") === "8" ||
                      localStorage.getItem("nivel") === "9" ||
                      localStorage.getItem("nivel") === "10" ||
                      localStorage.getItem("nivel") === "11" ||
                      localStorage.getItem("nivel") === "12" ||
                      (localStorage.getItem("grupo") ===
                        "99 NSD - NO SINDICALIZADOS" &&
                        localStorage.getItem("email") ===
                        "nferri@aeromexico.com") ||
                      (localStorage.getItem("grupo") ===
                        "99 NSD - NO SINDICALIZADOS" &&
                        localStorage.getItem("email") ===
                        "jsarvis@aeromexico.com") ? (
                      <Router>
                        <Link to="/recompensa-total/my-hr">
                          <img
                            className="ImageMenu"
                            src={menuWork}
                            alt="Work"
                          />
                          <span>{i18next.t("Profile.hr")}</span>
                        </Link>
                      </Router>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="BrNotifyWrapeakWrap noBluBack">
                    <a
                      onClick={() => this.openModal()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="ImageMenu" src={core} alt="Core" />
                      <span>{i18next.t("Profile.contactos")}</span>
                    </a>
                  </div>

                  <div className="BrNotifyWrapeakWrap">
                    <a href="/login" onClick={this.logout}>
                      <img className="ImageMenu" src={logout} alt="Logout" />
                      <span>{i18next.t("Profile.cerrarSesion")}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="NotificationMenu notify-container">
                <div className="NotifyWrap">
                  <p className="RedNumer" />
                  <img
                    className="IconNotify displayNotifyDesktop"
                    src={notify}
                    alt="Notify"
                  />
                  <img
                    className="IconNotify displayNotifyApp"
                    src={notifyApp}
                    alt="Notify"
                  />
                </div>
                <Notifications />
              </div>
              <div className="NotificationMenu notify-container Language">
                <a className="OptionWrap">
                  <div className="OptionCell">
                    <div
                      className="WrappperApp"
                      onClick={() => this.openModalLang()}
                    >
                      <div
                        className={`ImageMenuMovil Lang ${this.state.lang}`}
                      ></div>
                    </div>
                    <div className={`container-language ${this.state.lang}`}>
                      <div className="notification-column">
                        <a
                          class="notification-item"
                          onClick={() => this.onLangSelectedEvent("es")}
                        >
                          <p className="notification-text"> ES </p>
                        </a>
                        <a
                          class="notification-item"
                          onClick={() => this.onLangSelectedEvent("en")}
                        >
                          <p className="notification-text"> EN </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <input
            type="checkbox"
            className="openSidebarMenu"
            id="openSidebarMenu"
          />
          <label for="openSidebarMenu" class="sidebarIconToggle">
            <div class="spinner diagonal part-1"></div>
            <div class="spinner horizontal"></div>
            <div class="spinner diagonal part-2"></div>
          </label>

          <div id="sidebarMenu">
            <nav id="navMenuMobile">
              <ul class="sidebarMenuInner clearfix cd-accordion cd-accordion--animated margin-top-lg margin-bottom-lg">
                {/* INICIO */}
                <li class="cd-accordion__item">
                  <a href="/inicio" activeClassName="menuMovilActive">
                    {i18next.t("MainMenu.inicio")}
                  </a>
                </li>

                {/* NOSOTROS */}
                <li class="submenu cd-accordion__item cd-accordion__item--has-children">
                  <input class="cd-accordion__input" type="checkbox" name="group-1" id="group-1" />
                  <label class="cd-accordion__label cd-accordion__label--icon-folder" for="group-1" >
                      <span>{i18next.t("MainMenu.nosotros.self")}</span>
                  </label>
                  <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l1">
                    {/* GRUPO AEROMEXICO */}
                    <li class="sub-li submenu cd-accordion__item cd-accordion__item--has-children">
                      <input
                        class="cd-accordion__input"
                        type="checkbox"
                        name="sub-group-gaero"
                        id="sub-group-gaero"
                      />
                      <label
                        class="cd-accordion__label cd-accordion__label--icon-folder"
                        for="sub-group-gaero"
                      >
                        <span>
                          {i18next.t("MainMenu.nosotros.grupoAero.self")}
                        </span>
                      </label>
                      <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l2">
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros">
                              {i18next.t("MainMenu.nosotros.grupoAero.vision")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros#nuestra-cultura">
                              {i18next.t("MainMenu.nosotros.grupoAero.cultura")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros#subsidiarias-aeromexico">
                              {i18next.t("MainMenu.nosotros.grupoAero.subsi")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/nuestra-organizacion">
                              {i18next.t("MainMenu.nosotros.grupoAero.lideres")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/nuestra-flota">
                              {i18next.t("MainMenu.nosotros.grupoAero.flota")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros#nuestros-aeropuertos">
                              {i18next.t("MainMenu.nosotros.grupoAero.aero")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/responsabilidad-social">
                              {i18next.t("MainMenu.nosotros.sosten")}
                            </a>
                          </Router>
                        </li>
                      </ul>
                    </li>

                    {/* PLAN DE VUELO */}
                    <li class="sub-li cd-accordion__item">
                      <a
                        href={this.planVueloLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18next.t("MainMenu.nosotros.planVuelo")}
                      </a>
                    </li>

                    {/* ALIANZAS */}
                    <li class="sub-li submenu cd-accordion__item cd-accordion__item--has-children">
                      <input
                        class="cd-accordion__input"
                        type="checkbox"
                        name="sub-group-alianzas"
                        id="sub-group-alianzas"
                      />
                      <label
                        class="cd-accordion__label cd-accordion__label--icon-folder"
                        for="sub-group-alianzas"
                      >
                        <span>
                          Alianzas
                        </span>
                      </label>
                      <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l2">
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/jca-aeromexico-delta">
                              {i18next.t("MainMenu.nosotros.jca")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="https://www.aeromexico.com/es-mx/vuela-con-nosotros/otras-alianzas" target="_blank" rel="noopener noreferrer">
                              {i18next.t("MainMenu.nosotros.sky")}
                            </a>
                          </Router>
                        </li>
                      </ul>
                    </li>

                    {/* REPORTES */}
                    <li class="sub-li submenu cd-accordion__item cd-accordion__item--has-children">
                      <input
                        class="cd-accordion__input"
                        type="checkbox"
                        name="sub-group-reportes"
                        id="sub-group-reportes"
                      />
                      <label
                        class="cd-accordion__label cd-accordion__label--icon-folder"
                        for="sub-group-reportes"
                      >
                        <span>
                          {i18next.t("MainMenu.nosotros.reportes.self")}
                        </span>
                      </label>
                      <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l2">
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="https://www.aeromexico.com/es-mx/inversionistas/reportes-financieros" target="_blank">
                              {i18next.t("MainMenu.nosotros.reportes.opera")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="https://www.aeromexico.com/es-mx/inversionistas/reportes-financieros" target="_blank">
                              {i18next.t("MainMenu.nosotros.reportes.trimes")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/reporte-anual">
                              {i18next.t("MainMenu.nosotros.reportes.anual")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/boletin-industria">
                              {i18next.t(
                                "MainMenu.nosotros.reportes.industria"
                              )}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/responsabilidad-social#reportes-sostenibilidad">
                              {i18next.t("MainMenu.nosotros.reportes.sosten")}
                            </a>
                          </Router>
                        </li>
                      </ul>
                    </li>

                    {/* Juntos continuaremos volando */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/juntoscontinuaremosvolando">
                          Juntos continuaremos volando
                        </a>
                      </Router>
                    </li>

                    {/* CAPITULO 11*/}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/nosotros/seguimos-y-seguiremos-volando">
                          Capitulo 11
                        </a>
                      </Router>
                    </li>

                    {/* OTROS */}
                    <li class="sub-li submenu cd-accordion__item cd-accordion__item--has-children">
                      <input
                        class="cd-accordion__input"
                        type="checkbox"
                        name="sub-group-otros"
                        id="sub-group-otros"
                      />
                      <label
                        class="cd-accordion__label cd-accordion__label--icon-folder"
                        for="sub-group-otros"
                      >
                        <span>{i18next.t("MainMenu.nosotros.otros.self")}</span>
                      </label>
                      <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l2">
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href={this.defendamosNuestroLink()}>
                              {i18next.t("MainMenu.nosotros.cielo")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/gam-talks">
                              {i18next.t("MainMenu.nosotros.gam")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/logos-templates">
                              {i18next.t("MainMenu.nosotros.otros.identidad")}
                              <div class="subtitle">
                                {i18next.t(
                                  "MainMenu.nosotros.otros.identidadSub"
                                )}
                              </div>
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <a
                            onClick={() => this.openModal2()}
                            activeClassName="activeDrop"
                          >
                            {i18next.t("MainMenu.nosotros.otros.localidades")}
                          </a>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <a
                            href={i18next.t("MainMenu.nosotros.otros.estacionesLiga")}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18next.t("MainMenu.nosotros.otros.estaciones")}
                          </a>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a href="/nosotros/alfabeto">
                              {i18next.t("MainMenu.nosotros.otros.alfabeto")}
                            </a>
                          </Router>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                {/* SEGURIDAD */}
                <li class="submenu cd-accordion__item cd-accordion__item--has-children">
                  <input
                    class="cd-accordion__input"
                    type="checkbox"
                    name="group-2"
                    id="group-2"
                  />
                  <label
                    class="cd-accordion__label cd-accordion__label--icon-folder"
                    for="group-2"
                  >
                    <span>{i18next.t("MainMenu.seguridad.self")}</span>
                  </label>
                  <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l1">
                    {/* PON LA SEGURIDAD PRIMERO */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/seguridad">
                          {i18next.t("MainMenu.seguridad.primero")}
                        </a>
                      </Router>
                    </li>

                    {/* NOM-035 */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/seguridad/nom-035">
                          NOM-035
                        </a>
                      </Router>
                    </li>

                    {/* CULTURA JUSTA */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/seguridad#cultura-justa">
                          {i18next.t("MainMenu.seguridad.justa")}
                        </a>
                      </Router>
                    </li>

                    {/* PRINCIPIOS */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/seguridad#principios-de-seguridad">
                          {i18next.t("MainMenu.seguridad.principios")}
                        </a>
                      </Router>
                    </li>

                    {/* AREAS DE SEGURIDAD */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/seguridad#areas-de-seguridad">
                          {i18next.t("MainMenu.seguridad.areas")}
                        </a>
                      </Router>
                    </li>

                    {/* REPORTA O DENUNCIA */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/seguridad#reporta-denuncia">
                          {i18next.t("MainMenu.seguridad.reporta")}
                        </a>
                      </Router>
                    </li>

                    {/* NOTICIAS */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/noticias/seguridad">
                          {i18next.t("MainMenu.seguridad.noticias")}
                        </a>
                      </Router>
                    </li>

                    {/* Politicas de seguridad y no puntualidad */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/articles/2yo9ERxlWAlWHFxv3DhOXI">
                          {i18next.t("MainMenu.seguridad.politics")}
                        </a>
                      </Router>
                    </li>
                  </ul>
                </li>

                <li class="submenu cd-accordion__item cd-accordion__item--has-children">
                  <input
                    class="cd-accordion__input"
                    type="checkbox"
                    name="group-3"
                    id="group-3"
                  />
                  <label
                    class="cd-accordion__label cd-accordion__label--icon-folder"
                    for="group-3"
                  >
                    <span>{i18next.t("MainMenu.miExpAM.self")}</span>
                  </label>
                  <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l1">
                    {/* MI RECOMPENSA TOTAL */}
                    {(localStorage.getItem("nivel") === "1" &&
                      localStorage.getItem("grupo") !=
                      "99 NSD - NO SINDICALIZADOS") ||
                      localStorage.getItem("nivel") === "2" ||
                      localStorage.getItem("nivel") === "3" ||
                      localStorage.getItem("nivel") === "4" ||
                      localStorage.getItem("nivel") === "5" ||
                      localStorage.getItem("nivel") === "6" ||
                      localStorage.getItem("nivel") === "7" ||
                      localStorage.getItem("nivel") === "8" ||
                      localStorage.getItem("nivel") === "9" ||
                      localStorage.getItem("nivel") === "10" ||
                      localStorage.getItem("nivel") === "11" ||
                      localStorage.getItem("nivel") === "12" ||
                      (localStorage.getItem("grupo") ===
                        "99 NSD - NO SINDICALIZADOS" &&
                        localStorage.getItem("email") ===
                        "nferri@aeromexico.com") ||
                      (localStorage.getItem("grupo") ===
                        "99 NSD - NO SINDICALIZADOS" &&
                        localStorage.getItem("email") ===
                        "jsarvis@aeromexico.com") ? (
                      <li class="sub-li submenu cd-accordion__item cd-accordion__item--has-children">
                        <input
                          class="cd-accordion__input"
                          type="checkbox"
                          name="sub-group-recompensa"
                          id="sub-group-recompensa"
                        />
                        <label
                          class="cd-accordion__label cd-accordion__label--icon-folder"
                          for="sub-group-recompensa"
                        >
                          <span>
                            {i18next.t("MainMenu.miExpAM.recompensa.self")}
                          </span>
                        </label>
                        <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l2">
                          {validateMenu() && (
                            <li class="sub-li cd-accordion__item">
                              <Router>
                                <a href="/recompensa-total/perfil/mi-compensaciones">
                                  {i18next.t(
                                    "MainMenu.miExpAM.recompensa.compensa"
                                  )}
                                </a>
                              </Router>
                            </li>
                          )}
                          {/*Travel benefits condicionado */}
                          {(localStorage.getItem("compania") === "Aeroméxico" ||
                            localStorage.getItem("compania") ===
                            "Aeroméxico Connect") &&
                            isTravelBenefitsActive() ? (
                            <li class="sub-li cd-accordion__item">
                              <Router>
                                <a href="/recompensa-total/perfil/my-travel-benefits">
                                  {i18next.t(
                                    "MainMenu.miExpAM.recompensa.travel"
                                  )}
                                </a>
                              </Router>
                            </li>
                          ) : (
                            ""
                          )}
                          {(localStorage.getItem("compania") ===
                            "Aeroméxico Cargo" ||
                            localStorage.getItem("compania") ===
                            "Aeroméxico Formación" ||
                            localStorage.getItem("compania") ===
                            "Aeroméxico Servicios" ||
                            localStorage.getItem("compania") ===
                            "Centro de Servicios Compartidos" ||
                            isMockUserVentaEspecial()) &&
                            this.state.ventaEspecial ? (
                            <li class="sub-li cd-accordion__item">
                              <Router>
                                <a href="/recompensa-total/perfil/venta-especial">
                                  {i18next.t(
                                    "MainMenu.miExpAM.recompensa.venta"
                                  )}
                                </a>
                              </Router>
                            </li>
                          ) : (
                            ""
                          )}
                          <li class="sub-li cd-accordion__item">
                            <Router>
                              <a href="/recompensa-total/perfil/mis-beneficios">
                                {i18next.t("MainMenu.miExpAM.recompensa.benef")}
                              </a>
                            </Router>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* MI DESEMPEÑO Y DESARROLLO */}
                    {(localStorage.getItem("nivel") === "1" &&
                      localStorage.getItem("grupo") !=
                      "99 NSD - NO SINDICALIZADOS") ||
                      localStorage.getItem("nivel") === "2" ||
                      localStorage.getItem("nivel") === "3" ||
                      localStorage.getItem("nivel") === "4" ||
                      localStorage.getItem("nivel") === "5" ||
                      localStorage.getItem("nivel") === "6" ||
                      localStorage.getItem("nivel") === "7" ||
                      localStorage.getItem("nivel") === "8" ||
                      localStorage.getItem("nivel") === "9" ||
                      localStorage.getItem("nivel") === "10" ||
                      localStorage.getItem("nivel") === "11" ||
                      localStorage.getItem("nivel") === "12" ||
                      (localStorage.getItem("grupo") ===
                        "99 NSD - NO SINDICALIZADOS" &&
                        localStorage.getItem("email") ===
                        "nferri@aeromexico.com") ||
                      (localStorage.getItem("grupo") ===
                        "99 NSD - NO SINDICALIZADOS" &&
                        localStorage.getItem("email") ===
                        "jsarvis@aeromexico.com") ? (
                      <li class="sub-li submenu cd-accordion__item cd-accordion__item--has-children">
                        <input
                          class="cd-accordion__input"
                          type="checkbox"
                          name="sub-group-desarrollo"
                          id="sub-group-desarrollo"
                        />
                        <label
                          class="cd-accordion__label cd-accordion__label--icon-folder"
                          for="sub-group-desarrollo"
                        >
                          <span>
                            {i18next.t("MainMenu.miExpAM.miDesem.self")}
                          </span>
                        </label>
                        <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l2">
                          
                          <li class="sub-li cd-accordion__item">
                            <Router>
                              <a
                                activeClassName="activeDrop"
                                href="/recompensa-total/mi-desempeno-desarrollo"
                              >
                                {i18next.t(
                                  "MainMenu.miExpAM.miDesem.desarrolla"
                                )}
                              </a>
                            </Router>
                          </li>
                          <li class="sub-li cd-accordion__item">
                            <Router>
                              <a activeClassName="activeDrop" href="/oportunidades-carrera">
                                {i18next.t("MainMenu.miExpAM.miDesem.vacantes")}
                              </a>
                            </Router>
                          </li>

                          <li class="sub-li cd-accordion__item">
                            <a
                              activeClassName="activeDrop"
                              href="http://lms.aeromexico.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {i18next.t("MainMenu.miExpAM.miDesem.lms")}
                            </a>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      ""
                    )}

                    

                    {/* COMPLIANCE */}
                    {localStorage.getItem("nivel") === "1" ||
                      localStorage.getItem("nivel") === "2" ||
                      localStorage.getItem("nivel") === "3" ||
                      localStorage.getItem("nivel") === "4" ||
                      localStorage.getItem("nivel") === "5" ||
                      localStorage.getItem("nivel") === "6" ||
                      localStorage.getItem("nivel") === "7" ||
                      localStorage.getItem("nivel") === "8" ||
                      localStorage.getItem("nivel") === "9" ||
                      localStorage.getItem("nivel") === "10" ||
                      localStorage.getItem("nivel") === "11" ||
                      localStorage.getItem("nivel") === "12" ? (
                      <li class="sub-li cd-accordion__item">
                        <Router>
                          <a activeClassName="activeDrop" href="/compliance">
                            Compliance
                          </a>
                        </Router>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Juntos desde casa */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/juntos-desde-casa"
                        >
                          Juntos desde casa
                        </a>
                      </Router>
                    </li>

                    {/* HERRAMIENTAS DE COLABORACION */}

                    <li class="sub-li submenu cd-accordion__item cd-accordion__item--has-children">
                      <input
                        class="cd-accordion__input"
                        type="checkbox"
                        name="sub-group-tools"
                        id="sub-group-tools"
                      />
                      <label
                        class="cd-accordion__label cd-accordion__label--icon-folder"
                        for="sub-group-tools"
                      >
                        <span>
                          {i18next.t("MainMenu.miExpAM.herramientas.self")}
                        </span>
                      </label>
                      <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l2">
                        <li class="sub-li cd-accordion__item">
                          <a
                            href="https://outlook.office365.com/owa/?realm=aeromexico.com&exsvurl=1&ll-cc=2058&modurl=0"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18next.t("MainMenu.miExpAM.herramientas.outlook")}
                          </a>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <a
                            href="http://email.aeromexico.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18next.t("MainMenu.miExpAM.herramientas.office")}
                          </a>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <a
                            href="https://grupoaeromexico-my.sharepoint.com/_layouts/15/MySite.aspx?MySiteRedirect=AllDocuments"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18next.t(
                              "MainMenu.miExpAM.herramientas.onedrive"
                            )}
                          </a>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <a
                            href="https://aka.ms/mstfw"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18next.t("MainMenu.miExpAM.herramientas.teams")}
                          </a>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <a
                            href="https://webapp.kaiza.la/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18next.t("MainMenu.miExpAM.herramientas.kaizala")}
                          </a>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <a
                            href="https://grupoaeromexico.sharepoint.com/_layouts/15/sharepoint.aspx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18next.t(
                              "MainMenu.miExpAM.herramientas.sharepoint"
                            )}
                          </a>
                        </li>
                      </ul>
                    </li>

                    {/* AUTOSERVICIO CORE*/}
                    <li class="sub-li cd-accordion__item">
                      <a
                        href="http://autoservicio.aeromexico.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18next.t("MainMenu.miExpAM.core")}
                      </a>
                    </li>

                    {/* VIAJES EN COMISION */}
                    <li class="sub-li submenu cd-accordion__item cd-accordion__item--has-children">
                      <input
                        class="cd-accordion__input"
                        type="checkbox"
                        name="sub-group-viajes"
                        id="sub-group-viajes"
                      />
                      <label
                        class="cd-accordion__label cd-accordion__label--icon-folder"
                        for="sub-group-viajes"
                      >
                        <span>{i18next.t("MainMenu.miExpAM.viajes.self")}</span>
                      </label>
                      <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l2">
                        <li class="sub-li cd-accordion__item">
                          <Router>
                            <a 
                              href="/recompensa-total/viajes-aeromexico"
                              activeClassName="activeDrop"
                            >
                              {i18next.t("MainMenu.miExpAM.viajes.conAero")}
                            </a>
                          </Router>
                        </li>
                        <li class="sub-li cd-accordion__item">
                          <a
                            href="https://grupoaeromexico.sharepoint.com/sites/Pass_Bureau_Aeromexico/Paginas/Inicio.aspx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i18next.t("MainMenu.miExpAM.viajes.conOtras")}
                            <div class="subtitle">
                              {i18next.t("MainMenu.miExpAM.viajes.conOtrasSub")}
                            </div>
                          </a>
                        </li>
                      </ul>
                    </li>

                    {/* NOTICIAS */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/nuestragente"
                        >
                          {i18next.t("MainMenu.miExpAM.noticias")}
                        </a>
                      </Router>
                    </li>
                  </ul>
                </li>

                {/* EXPERIENCIA DEL CLIENTE */}
                <li class="submenu cd-accordion__item cd-accordion__item--has-children">
                  <input
                    class="cd-accordion__input"
                    type="checkbox"
                    name="group-4"
                    id="group-4"
                  />
                  <label
                    class="cd-accordion__label cd-accordion__label--icon-folder"
                    for="group-4"
                  >
                    <span>{i18next.t("MainMenu.expCliente.self")}</span>
                  </label>
                  <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l1">
                    {/* CUSTOMER EXPERIENCE */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/experiencia-cliente/net-promoter-score"
                          activeClassName="activeDrop"
                        >
                          {i18next.t("MainMenu.expCliente.cx")}
                        </a>
                      </Router>
                    </li>

                    {/* NPS */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/experiencia-cliente/net-promoter-score#npsnumber"
                          activeClassName="activeDrop"
                        >
                          {i18next.t("MainMenu.expCliente.nps")}
                        </a>
                      </Router>
                    </li>

                    {/* LOGROS CX */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/experiencia-cliente/logros"
                          activeClassName="activeDrop"
                        >
                          {i18next.t("MainMenu.expCliente.logrosCx")}
                        </a>
                      </Router>
                    </li>

                    {/* CX EVOLUTION */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/experiencia-cliente/cx-evolution"
                          activeClassName="activeDrop"
                        >
                          {i18next.t("MainMenu.expCliente.cxEvolution")}
                        </a>
                      </Router>
                    </li>

                    {/* POLITICAS Y PROCESOS */}
                    <li class="sub-li cd-accordion__item">
                      <a
                        href="https://grupoaeromexico.sharepoint.com/sites/AMPol%C3%ADticasYProcesos/Pages/Inicio.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18next.t("MainMenu.expCliente.politicas")}
                      </a>
                    </li>
                  </ul>
                </li>

                {/* NOTICIAS */}
                <li class="submenu cd-accordion__item cd-accordion__item--has-children">
                  <input
                    class="cd-accordion__input"
                    type="checkbox"
                    name="group-5"
                    id="group-5"
                  />
                  <label
                    class="cd-accordion__label cd-accordion__label--icon-folder"
                    for="group-5"
                  >
                    <span>{i18next.t("MainMenu.noticias.self")}</span>
                  </label>
                  <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l1">
                    {/* ULTIMAS */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/ultimas-noticias"
                        >
                          {i18next.t("MainMenu.noticias.ultimas")}
                        </a>
                      </Router>
                    </li>
                    {/* SEGURIDAD */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/seguridad"
                        >
                          {i18next.t("MainMenu.noticias.seguridad")}
                        </a>
                      </Router>
                    </li>
                    {/* NUESTRA GENTE */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/nuestragente"
                        >
                          {i18next.t("MainMenu.noticias.gente")}
                        </a>
                      </Router>
                    </li>
                    {/* EXPERIENCIA */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/experienciacliente"
                        >
                          {i18next.t("MainMenu.noticias.expCliente")}
                        </a>
                      </Router>
                    </li>
                    {/* JCA */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/delta"
                        >
                          {i18next.t("MainMenu.noticias.jca")}
                        </a>
                      </Router>
                    </li>
                    {/* COMERCIAL */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/comercial"
                        >
                          {i18next.t("MainMenu.noticias.comercial")}
                        </a>
                      </Router>
                    </li>
                    {/* OPERACIONES */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/operaciones"
                        >
                          {i18next.t("MainMenu.noticias.opera")}
                        </a>
                      </Router>
                    </li>
                    {/* SKYTEAM */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/skyteam"
                        >
                          {i18next.t("MainMenu.noticias.sky")}
                        </a>
                      </Router>
                    </li>
                    {/* SOSTENIBILIDAD */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a
                          activeClassName="activeDrop"
                          href="/noticias/sostenibilidad"
                        >
                          {i18next.t("MainMenu.noticias.sosten")}
                        </a>
                      </Router>
                    </li>
                  </ul>
                </li>

                {/* VACANTES */}
                <li class="sub-li cd-accordion__item">
                  <Router>
                    <a href="/oportunidades-carrera">
                      {i18next.t("MainMenu.miExpAM.miDesem.vacantes")}
                    </a>
                  </Router>
                </li>

                {/* MAS */}
                <li class="submenu cd-accordion__item cd-accordion__item--has-children">
                  <input
                    class="cd-accordion__input"
                    type="checkbox"
                    name="group-6"
                    id="group-6"
                  />
                  <label
                    class="cd-accordion__label cd-accordion__label--icon-folder"
                    for="group-6"
                  >
                    <span>{i18next.t("MainMenu.mas.self")}</span>
                  </label>
                  <ul class="sub-ul cd-accordion__sub cd-accordion__sub--l1">

                    <li>
                      <Router>
                        <Link to="/misa">{i18next.t("MainMenu.mas.misa")}</Link>
                      </Router>
                    </li>
                    {/* TRIQUES */}
                    <li class="sub-li cd-accordion__item">
                      <Router>
                        <a href="/buy-and-sell">
                          {i18next.t("MainMenu.mas.triques")}
                        </a>
                      </Router>
                    </li>
                    {/* POLITICAS */}
                    <li class="sub-li cd-accordion__item">
                      <a
                        href="http://intranet.aeromexico.com.mx/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18next.t("MainMenu.mas.politicas")}
                      </a>
                    </li>
                    {/* MANUAL */}
                    <li class="sub-li cd-accordion__item">
                      <a
                        href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ETd2bDN0vn9MprHyOiR3xWMBrDUrEvcYpTn4IfRjDw-SPA?e=locK1e"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18next.t("MainMenu.mas.manual")}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
